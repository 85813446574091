import { Divider } from 'antd-mobile'
import './DoctorOverview.scss';
import docktorPic from '../../assets/doctor.png';
import phone from '../../assets/phone.png';
import down from '../../assets/down.png';
import Popup from '../Popup';
import { appConfig } from '../../constants';

const config = appConfig();

const overview = [
    {
        title: '学术任职',
        content: '中国老年医学学会脑卒中分会委员，江苏省卒中学会理事，江苏省卒中学会脑脊髓血管病复合手术专委会常务委员，江苏省研究型医院学会神经介入专委会常务委员，连云港市医学会脑卒中分会副主任委员。',
    },
    {
        title: '科研成绩',
        content: '获连云港市科技进步二等奖1项，江苏省医学新技术引进二等奖1项，连云港市医学新技术引进一等奖1项、二等奖1项。',
    },
    {
        title: '个人荣誉',
        content: '江苏省“333高层次人才”、连云港市“521高层次人才”、连云港市“花果山英才”领军人才第一层次，南京医科大学康达学院教学督导专家、外科学基础教研室主任。',
    },
    {
        title: '专业特长',
        content: '擅长:脑血管病的微创神经介入治疗，如颅内动脉瘤栓塞术、脑动静脉畸形栓塞术、颈内动脉海绵窦瘘栓塞术、颅内外血管狭窄支架植入术、急性缺血性卒中的动脉取栓术等。',
    },
]

function Doctor({ visible, onClose }) {
    return (
        <Popup visible={visible} onClose={onClose}>
            <div className='Doctor-detail'>
                <div className='Doctor-detail-info'>
                    <div className='Doctor-detail-head'>
                        <img src={config.header} />
                    </div>
                    <div className='Doctor-detail-overview'>
                        <div className='Doctor-detail-title'>
                            {config.name}
                            <img src={phone} className='Doctor-detail-phone' />
                        </div>
                        <div className='Doctor-detail-introduce'>
                            {
                                config.info.map(item => (
                                    <div>{item}</div>
                                ))
                            }
                        </div>
                    </div>
                </div>

                <div className='Doctor-divider'>
                    <Divider />
                </div>

                <div className='Doctor-overviews'>
                    {(config.overview || []).map(item => (
                        <div className='Doctor-overview-item' style={item.style ? item.style : {}}>
                            {item.title && <div className='title'>{item.title}</div>}
                            <div className='content'>{item.content}</div>
                        </div>
                    ))}
                </div>

                <img src={down} className='Doctor-detail-close-btn' onClick={onClose} />
            </div>
        </Popup>
    );
}

export default Doctor;
