import { Popup } from 'antd-mobile';
import "./popup.scss";

function CustomPopup({ visible, onClose, children }) {
    return (
        <Popup
            visible={visible}
            onMaskClick={onClose}
            className="custom-popup"
        >
            {children}
        </Popup>
    );
}

export default CustomPopup;