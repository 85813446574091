import {
    apiKey,
    appConfig,
} from '../constants';

const config = appConfig()

export default class ChatHelper {
    constructor(onChange) {
        this.chating = false;
        this.onChange = onChange;
        this.conversation_id = null;
    }
    sendMessage(query, complete) {
        if (this.chating) return;
        this.chating = true;
        this.answer = "";
        fetch(config.chatUrl, {
            body: JSON.stringify({
                "inputs": {},
                "query": query,
                "response_mode": "streaming",
                "conversation_id": this.conversation_id,
                "user": "abc-123",
            }),
            method: "post",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${apiKey}`,
            },
        }).then((res) => {
            const reader = res.body.getReader();
            this.complete = complete;
            this.onMessage(reader);
        }).catch((e) => {
            console.error(e)
        })
    }
    onMessage(reader) {
        const decoder = new TextDecoder('utf-8')
        let buffer = '';
        let bufferObj;
        let isFirstMessage = true
        reader.read().then((result) => {
            if (result.done) {
                this.chating = false;
                if (typeof this.complete === 'function') {
                    this.complete(this.answer);
                    this.complete = null;
                }
                return
            }
            buffer += decoder.decode(result.value, { stream: true })
            const lines = buffer.split('\n')
            lines.forEach((message) => {
                if (message.startsWith('data: ')) { // check if it starts with data:
                    // console.log(message);
                    try {
                        bufferObj = JSON.parse(message.substring(6)) // remove data: and parse as json
                        this.conversation_id = bufferObj.conversation_id;
                    }
                    catch (e) {
                        // mute handle message cut off
                        console.error(e);
                        return
                    }
                    if (bufferObj.status === 400 || !bufferObj.event) {
                        console.error('接收消息失败');
                        return
                    }
                    this.answer = this.answer + bufferObj.answer;
                    this.onChange(this.answer, bufferObj.conversation_id)
                    isFirstMessage = false;
                }
            })
            buffer = lines[lines.length - 1]
            this.onMessage(reader)
        })
    }
}

