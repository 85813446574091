import { useEffect, useState } from 'react';
import { useNavigate, useLoaderData } from 'react-router-dom';
import { Button, Steps, Space, Empty } from 'antd-mobile'
import {
    CheckCircleFill,
    ClockCircleFill,
    HandPayCircleOutline,
} from 'antd-mobile-icons'
import back from '../../assets/back.png'
import Link from '../../components/Link'
import Title from '../../components/Title';
import Wraper from '../../components/Wraper'

import getPromptData from '../../utils/getPromptData';

import './index.scss';

const { Step } = Steps

function Follow(props) {
    const [loaderData, setLoaderData] = useState([]);
    const [map, setMap] = useState([]);
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate();
    const backPage = () => {
        navigate(-1)
    }

    useEffect(() => {
        const newMap = loaderData.map((item, index) => {
            const { createAt, form } = item;
            const date = new Date(createAt);
            return {
                ...item,
                date: `${date.getMonth() + 1}月${date.getDate()}日`,
                illustrate: index === 0 ? '一周' : '一月',
            }
        })

        setMap(newMap);
    }, [loaderData])

    useEffect(() => {
        getPromptData(navigate, (data) => {
            setLoaderData(data)
            setLoading(false);
        });
    }, [])

    return (
        <>
            <Wraper>
                <div className='Follow'>
                    <Title title="随诊记录" onClick={backPage} />
                    {
                        map.length ? <Steps
                            direction='vertical'
                            current={1}
                            className='Follow-steps'
                        >
                            {map.map((item, index) => (
                                <Step
                                    key={index}
                                    className='Follow-step'
                                    title={
                                        <div block direction='vertical' className='Follow-step-title'>
                                            <div>{item.date}</div>
                                            <div className='Follow-step-title-info'>术后{item.illustrate}随诊记录</div>
                                        </div>
                                    }
                                    description={
                                        <div block direction='vertical' className='Follow-step-content'>
                                            <Link to={`/follow/${index}`}><Button className='Follow-step-btn'>查看记录</Button></Link>
                                        </div>
                                    }
                                    icon={<div className='Follow-icon' />}
                                />
                            ))}
                        </Steps> : <div style={{ marginTop: 200 }}><Empty description={loading ? "加载中" : "暂无数据"} /></div>
                    }
                </div>
            </Wraper>
        </>
    );
}

export default Follow;
