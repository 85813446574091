import { Link, useNavigate, useParams } from 'react-router-dom';
import Title from '../../components/Title';
import "./index.scss";

const User = () => (
    <div className='Protocol-container'>
        <h1>卒息常谈服务协议</h1>
        欢迎来到「卒息常谈」移动应用（包括但不限于「卒息常谈」H5页面、移动APP和微信小程序）。通过它您可以访问我们提供的健康服务。「卒息常谈」移动应用可在移动设备上免费下载或访问。 作为您使用「卒息常谈」的先决条件，您在使用「卒息常谈」前，应当事先认真阅读本服务协议，请您着重阅读字体加粗的条款。如果您同意本服务协议，或您实际上浏览、登录、使用及连接「卒息常谈」，均应当被视为您已完全了解、接受并同意遵守本服务协议的所有内容，并同意受其约束。如果您不同意本服务协议中任何的内容和条款，则您不应当访问或使用「卒息常谈」。 以下内容和条款构成了您和「卒息常谈」（为进一步明确，本服务协议中，「卒息常谈」同时指代「卒息常谈」的运营商北京左医科技有限公司）之间具有约束力的协议。
        <h2>1.本服务协议的组成与修订</h2>
        <h3>1.1</h3>
        <p>本服务协议内容包括：如下所示的全部条款和条件，以及「卒息常谈」已经发布或将来可能发布的各项补充、更新、修订、规则、声明、通知和解释。除非另有约定，所有上述补充、更新、修订、规则、声明、通知和解释都被视为本服务协议的组成部分，具有同等法律效力。</p>
        <h3>1.2</h3>
        <p>「卒息常谈」有权在必要时单方修改服务协议，并通过「卒息常谈」网站和/或「卒息常谈」移动应用发布修改变更，不再另行单独通知。修改后的服务协议一经发布即代替旧版本服务协议，自动更新为您与「卒息常谈」之间具有约束力的协议。您可以随时登录并查询本服务协议的最新版本，并且您有义务不时关注并阅读最新版的服务协议。如在服务协议更新并生效后，您继续使用「卒息常谈」服务，则视为您已充分阅读、完全了解并接受服务协议的更新并同意遵守更新后的服务协议。如您不接受「卒息常谈」更新后的服务协议，请您立即停止使用「卒息常谈」服务。</p>
        <h3>1.3</h3>
        <p>您特此声明，已经完全理解本服务协议所有内容，并不存在任何重大误解；同时，您认可本服务协议并不存在显失公平的情形。</p>
        <h2>2. 服务内容与免责声明</h2>
        <h3>2.1</h3>
        <p>我们通过「卒息常谈」平台为您提供健康咨询服务，服务的具体内容可能不时发生变更或增减。</p>
        <h3>2.2</h3>
        <p>您在此明确知悉，「卒息常谈」提供的任何服务不适合也不应当被视为任何形式的诊疗服务。我们不提供任何紧急医疗服务或面对面的诊断或治疗。该服务旨在补充而不是取代医生、医院或其他医疗服务提供者为您提供的诊疗服务，您不能凭借或依据「卒息常谈」提供的信息进行疾病治疗。如果您参考「卒息常谈」提供的信息，在自行治疗中出现问题，「卒息常谈」不承担任何责任。</p>
        <h3>2.3</h3>
        <p>您通过「卒息常谈」获得的数据信息，仅供您参考使用，其内容并不代表「卒息常谈」向您提供医学建议，也不构成任何学术观点，也不代表「卒息常谈」就任何建议、信息或数据的真实性提供任何形式的保证。</p>
        <h3>2.4</h3>
        <p>「卒息常谈」平台所提供的任何服务和信息，都将受限于您通过平台向「卒息常谈」提供的信息。您有责任确保这一信息是正确、完整且无误导的，如果您提供的信息不准确、不完整，或存在任何误导、歧义或错误（无论是由您的故意或过失所致），可能影响到「卒息常谈」提供给您的信息和建议，由此产生的后果由您自行承担。</p>
        <h3>2.5</h3>
        <p>「卒息常谈」不提供医疗诊断或提供处方，我们建议您寻求专业医生的建议，在用药前咨询具有执业资格的医务人员并遵循医嘱，并按照处方购买和服用药物。在紧急情况下，您应当立即呼叫急诊服务。</p>
        <h3>2.6</h3>
        <p>「卒息常谈」负责「按现状」和「可得到」的状态向您提供服务。除非另有明确的书面说明，「卒息常谈」不对其运营及其包含在客户端上的信息、内容、材料、产品（包括软件）或服务作任何形式的、明示或默示的声明或担保（根据中华人民共和国法律另有规定的以外）。</p>
        <h3>2.7</h3>
        <p>「卒息常谈」不担保本客户端所包含的或以其它方式通过本客户端提供给您的全部信息、内容、材料、产品（包括软件）和服务、其服务器或从本客户端发出的电子信件、信息没有病毒或其他有害成分。</p>
        <h3>2.8</h3>
        <p>「卒息常谈」依法律规定承担基础保障义务，但无法对由于「卒息常谈」停机维护、信息网络设备维护、连接故障，电脑、通讯或其他系统的故障、电力故障、罢工、暴乱、火灾、洪水、风暴、爆炸、战争、政府行为、司法行政机关的命令或因第三方原因而给您造成的损害结果承担责任。</p>
        <h2>3.帐号建立</h2>
        <h3>3.1</h3>
        <p>您以勾选、填写或是其他形式同意本服务协议内容并完成注册程序，即表示您已充分阅读、理解、接受并同意本服务协议的全部内容，并与「卒息常谈」达成一致，成为「卒息常谈」的正式用户。</p>
        <h3>3.2</h3>
        <p>您确认自己已经年满18周岁，具备中华人民共和国法律规定的及您开始注册程序或使用服务时所在国家或地区的相关法律规定的与您行为相适应的民事行为能力，能够独立享受「卒息常谈」提供的服务，并承担相应的法律责任。如果您不具备前述与您行为相适应的民事行为能力，您只能在父母或监护人的监护参与下才能使用「卒息常谈」，否则您及您的监护人应依据法律规定承担因此导致的一切后果。</p>
        <h3>3.3</h3>
        <p>您须确保您不是任何国家、国际组织或者地域实施的贸易限制、制裁或其他法律、规则限制的对象，否则您可能无法正常注册或使用服务，并且「卒息常谈」可能中断向您提供的全部或部分服务。</p>
        <h3>3.4</h3>
        <p>一旦注册成功，您就成为「卒息常谈」的合法用户，将得到一个用户账号。您通过该账号发出的指令、操作均应当是不可操作的，您应当对以您的账号进行的所有活动和事件承担全部法律责任。「卒息常谈」无法甄别该账号的实际使用者，因此该账号只允许您使用，不得以租借、转让、转卖等形式提供给第三方，否则您应当承担由此产生的责任，并与实际使用账号的第三方承担连带责任。</p>
        <h3>3.5</h3>
        <p>您注册时，在账号名称、头像和简介等注册信息中不得出现违法和不良信息，否则「卒息常谈」有权拒绝您的注册申请或是拒绝提供服务，并关闭该账号。</p>
        <h3>3.6</h3>
        <p>您应当自行维护账号和密码的安全性。若您发现任何非法使用您的用户账号、账号在未授权的情况遭到使用或存在其他安全漏洞的情况，请立即通告「卒息常谈」并向公安机关报案。
            对于非「卒息常谈」原因造成的账号、密码等信息被冒用、盗用或非法使用，由此引起的一切风险、责任、损失、费用等应由您自行承担。</p>
        <h2>4. 费用支付</h2>
        <h3>4.1</h3>
        <p>「卒息常谈」在提供服务时，可能会对部分服务收取一定费用，「卒息常谈」将会对收费服务给予明确的提示，如您拒绝支付此类费用，「卒息常谈」有权不提供相关服务。</p>
        <h3>4.2</h3>
        <p>「卒息常谈」仅提供相关的服务，除此之外与服务有关的设备（如电脑、调制解调器及其他与接入互联网有关的装置）及所需的费用（如为接入互联网而支付的电话费及上网费）均应由您自行负担。</p>
        <h2>5. 信息提供、收集与使用</h2>
        <h3>5.1</h3>
        <p>您应当自行诚信地向「卒息常谈」提供「卒息常谈」要求的资料，且该等提供视为您自愿提供，您同意提供的各项资料均应当是真实、准确且合法有效的。若您的注册资料有任何变动，您应当更新注册资料。如果「卒息常谈」按您最后一次提供的信息与您联系未果，或您未按「卒息常谈」的要求及时提供信息，或您提供的信息存在明显不实的，您将承担因此对您自身、他人及「卒息常谈」造成的全部损失与不利后果。</p>
        <h3>5.2</h3>
        <p>在您注册和使用「卒息常谈」的过程中，「卒息常谈」可能会收集您的如下信息：</p>
        <p>(1)o个人信息：指能够独立或与其他信息结合后识别用户身份的信息、内容或资料，包括您的真实姓名、身份证号码、职业、职务、手机或固定电话号码、电子邮件信息、第三方账号信息、通讯地址等，以及您向「卒息常谈」提交的个人健康和医疗信息，包括但不限于个人身高、体重、性别、年龄、血型、病征、症状、病史、用药情况、就医经历等；</p>
        <p>(2)o使用信息：您在「卒息常谈」上，包括提问、回答、浏览、操作状态、使用记录、使用习惯等在内的全部记录信息、您的设备型号、设备识别码、操作系统、分辨率、电信运营商等，以及您在使用服务的过程中的设备信息、日志信息、位置信息、唯一应用程序编号、本地存储、Cookie或类似技术信息；</p>
        <p>(3)o第三方平台记录信息：若您以腾讯微信或其他第三方平台账号登录「卒息常谈」，「卒息常谈」可能收集您在第三方平台填写、登记、公布、记录的全部信息。</p>
        <h3>5.3</h3>
        <p>为向您提供服务及提升服务质量，就您向「卒息常谈」提供的资料和「卒息常谈」收集的各项信息，您同意「卒息常谈」将在合理范围内使用个人信息、使用信息以及第三方平台记录信息，包括：</p>
        <p>(1)o向您提供您使用的各项服务，并维护、改进这些服务。</p>
        <p>(2)o通过短信、电话、电子邮件、即时通信的客户端（网页）或者其他合法方式，依据前述您的个人信息向您发送包括订单信息、专题推荐、促销活动等信息。</p>
        <p>(3)o用于预防、发现、调查欺诈、危害安全、非法或违反与「卒息常谈」或「卒息常谈」关联方、「卒息常谈」合作伙伴的协议、政策或规则的行为，以保护您、其他「卒息常谈」用户，或「卒息常谈」或「卒息常谈」关联方、「卒息常谈」合作伙伴的合法权益。</p>
        <p>(4)o用于数据统计与分析。</p>
        <p>(5)o经您允许的其他用途。</p>
        <h3>5.4</h3>
        <p>「卒息常谈」对个人信息、使用信息以及第三方平台记录信息的使用无需其他意思表示，无需向您支付任何费用。</p>
        <h3>5.5</h3>
        <p>「卒息常谈」对您的个人信息承担保密义务，不会为满足第三方的营销目的而向其出售或出租您的任何个人信息，「卒息常谈」会在下列情况下才将您的个人信息与第三方共享：</p>
        <p>(1)o事先获得您的同意或授权情况下分享您的个人信息。</p>
        <p>(2)o根据法律法规的规定或行政或司法机构的要求分享您的个人信息。</p>
        <p>(3)o向「卒息常谈」关联方分享您的个人信息。</p>
        <p>(4)o向可信赖的「卒息常谈」合作伙伴提供您的个人信息，让他们根据「卒息常谈」的指示并遵循本服务协议以及其他任何相应的保密和安全措施来为「卒息常谈」处理您的个人信息。</p>
        <p>(5)o只有共享您的个人信息，才能提供您需要的服务，或处理您与他人的纠纷或争议。</p>
        <p>(6)o如您出现违反中华人民共和国有关法律、法规或「卒息常谈」相关协议或相关规则的情况，需要向第三方披露。</p>
        <p>(7)o为维护「卒息常谈」、「卒息常谈」关联方、「卒息常谈」合作伙伴或用户的合法权益。</p>
        <h2>6. 用户言行</h2>
        <h3>6.1</h3>
        <p>您在使用「卒息常谈」时，必须严格遵守如下规则：</p>
        <p>(1)o不得传输或发表：煽动抗拒、破坏宪法和法律、行政法规实施的言论，煽动颠覆国家政权，推翻社会主义制度的言论，煽动分裂国家、破坏国家统一的的言论，煽动民族仇恨、民族歧视、破坏民族团结的言论；违背社会风俗和社会道德的言论；</p>
        <p>(2)o从中华人民共和国大陆向境外传输资料信息时必须符合中华人民共和国有关法规；</p>
        <p>(3)o不得利用「卒息常谈」从事洗钱、窃取商业秘密、窃取个人信息等违法犯罪活动；</p>
        <p>(4)o不得干扰本客户端的正常运转，不得侵入本客户端及国家计算机信息系统；</p>
        <p>(5)o不得传输、发表或散播任何违法犯罪的、骚扰性的、中伤他人的、辱骂性的、诽谤性的、恐吓性的、伤害性的、淫秽的、不文明或涉及色情、赌博、暴力、凶杀、恐怖、非法宗教或者教唆犯罪的等信息资料；</p>
        <p>(6)o不得传输或发表损害国家社会公共利益和涉及国家安全的信息资料或言论；</p>
        <p>(7)o不得利用在「卒息常谈」注册的账号进行「卒息常谈」未明示允许的牟利性经营活动；</p>
        <p>(8)o不得发布任何侵犯他人著作权、商标权、专利等知识产权或隐私权等合法权利的内容；</p>
        <p>(9)o不得侵入本站及国家计算机信息系统，不得传播病毒、木马、蠕虫、定时炸弹等可能对「卒息常谈」造成伤害或影响其正常运转的恶意病毒或程序，或以任何形式修改、干扰、拦截、破坏或攻击「卒息常谈」平台或服务，或规避、禁用或以其他方式干扰平台的任何与安全相关的功能；</p>
        <p>(10)o不得允许他人以您的名义使用平台或服务；</p>
        <p>(11)o不得发送垃圾或重复的信息；</p>
        <p>(12)o不得教唆其他方从事本条所禁止的行为；</p>
        <h3>6.2</h3>
        <p>您应关注并遵守「卒息常谈」不时公布或修改的各类合法规则规定。「卒息常谈」保有删除各类不符合法律政策或不真实的信息内容而无须通知您的权利。若您未遵守以上规定的，「卒息常谈」有权作出独立判断并采取暂停或关闭用户帐号等措施。您须对自己在网上的言论和行为承担法律责任。</p>
        <h2>7. 知识产权</h2>
        <h3>7.1</h3>
        <p>对于您通过「卒息常谈」所发布的任何公开信息，用户在此确认并同意「卒息常谈」在全球范围内对上述公开信息享有免费的、永久性的、不可撤销的、非独家的和完全再许可的权利和许可，并以使用、复制、修改、改编、汇编、出版、翻译、据以创作衍生作品、传播、表演和公开展示此等内容（整体或部分），和/或将此等内容编入当前已知的或以后开发的其他任何形式的作品、媒体或技术中，以及其他任何商业目的。为进一步明确，本服务协议已经构成《中华人民共和国著作权法》及相关法律规定的著作财产权等权利转让书面协议，其效力及于用户在「卒息常谈」上发布的任何受著作权法保护的作品内容，无论该等内容形成于本服务协议订立前还是本服务协议订立后。</p>
        <h3>7.2</h3>
        <p>「卒息常谈」网站向您展示的和/或「卒息常谈」向您提供服务中的所有产品、技术、软件、程序、数据、文字、图像、图片、照片、音频、视频、图表、色彩、版面设计、电子文档及其他信息、内容、资料的所有权利，包括但不限于版权、商标权、专利权、商业秘密及其他所有相关权利，均归「卒息常谈」所有，并受中华人民共和国和国际版权法的保护。未经「卒息常谈」明确书面授权，任何人不得擅自使用，包括但不限于通过任何机器人、爬虫等程序或设备监视、复制、传播、展示、镜像、上载、下载「卒息常谈」网站内的任何内容。访问「卒息常谈」网站和/或使用「卒息常谈」服务并无法让您获得所访问和/或您所使用的「卒息常谈」服务中的信息、内容、资料的任何权利。除非您获得相关信息、内容、资料的所有者的许可或通过其他方式获得法律的许可，您不得使用「卒息常谈」网站向您展示的和/或「卒息常谈」向您提供服务内中的任何信息、内容、资料。</p>
        <h3>7.3</h3>
        <p>「卒息常谈」上展示的“卒息常谈”等文字、设计、图形及其组成部分，以及「卒息常谈」网站上展示的其他标识、徵记、产品和服务名称，均属于「卒息常谈」在中华人民共和国（包括港澳台地区）和/或其它国家/地区的商标。未经「卒息常谈」明确书面授权，任何人不得以任何方式在任何国家或区域展示、使用、申请或注册、或以任何其他方式进行处理，也不得在任何国家或区域向他人表明您有权展示、使用、申请或注册、或以任何其他方式进行处理。本服务协议并未授予您使用、申请或注册「卒息常谈」网站上展示的或「卒息常谈」服务中所用的任何商标的权利。</p>
        <h2>8. 对平台和服务的更改</h2>
        <h3>8.1</h3>
        <p>我们将不断更新和完善「卒息常谈」，试图为您提供新的、创新的服务和特色。为了改善和更新的平台，我们可能需要更新、停止提供和/或改善服务。这些变化可能会影响您的使用。任何更改都可能涉及到服务内容以及相应的信息、数据被删除、更改或重置，「卒息常谈」对此不承担任何责任。</p>
        <h3>8.2</h3>
        <p>我们可能会通过「卒息常谈」官方网站或应用程序商店发布的「卒息常谈」的程序更新。除非您将「卒息常谈」应用程序更新到最新版本并接受任何新的服务协议，否则您可能无法使用「卒息常谈」最新版本的功能。因此，为了使用「卒息常谈」提供的服务，您可能需要不时通过各个渠道查看「卒息常谈」的更新信息。</p>
        <h3>8.3</h3>
        <p>我们将尽力在可能和合理的情况下与您联系，让您了解任何「卒息常谈」的重大更新。如果您对更新不满意，您可以在任何时候终止您的服务使用。</p>
        <h2>9. 终止与违约责任</h2>
        <h3>9.1</h3>
        <p>当您出现下列情况时，「卒息常谈」有权通知您终止本服务协议：</p>
        <p>(1)</p>
        <p>o您在使用服务过程中违反有关法律法规规定的，或违反本服务协议约定的；</p>
        <p>(2)</p>
        <p>o您存在欺诈、盗用他人账号、骗取他人财物、售假、扰乱市场秩序、采取不正当手段谋利等行为的，或者您存在危及他人交易安全或账号安全风险等行为的；</p>
        <p>(3)</p>
        <p>o滥用「卒息常谈」网站或「卒息常谈」提供的服务；</p>
        <p>(4)</p>
        <p>o其它「卒息常谈」认为应当终止的情况。</p>
        <h3>9.2</h3>
        <p>「卒息常谈」根据本服务协议第9.1条约定终止本服务协议时，「卒息常谈」有权立即查封您的账号，「卒息常谈」有权中止或终止根据本服务协议向您提供的服务。本服务协议终止后，除法律有明确规定外，「卒息常谈」无义务向您或您指定的第三方披露您账号中的任何信息。本服务协议终止后，「卒息常谈」有权（但无义务）继续保存您留存于「卒息常谈」的各类信息。本服务协议终止后，对于您过往出现的本服务协议第9.1条所列情况，「卒息常谈」仍可根据本服务协议向您追究相应法律责任。此外，「卒息常谈」可将对您违反本服务协议行为及相应处理措施信息以及其他经国家行政或司法机关生效法律文书确认的违法信息在「卒息常谈」上予以公示。</p>
        <h3>9.3</h3>
        <p>如果您的行为使「卒息常谈」及其关联公司、员工、客户和合作伙伴遭受损失（包括自身的直接经济损失、商誉损失及对外支付的赔偿金、和解款、律师费、诉讼费等间接经济损失），您应赔偿「卒息常谈」及其关联公司、员工、客户和合作伙伴的上述全部损失。如果您的行为使「卒息常谈」及其关联公司、员工、客户和合作伙伴遭受第三人主张权利，「卒息常谈」及其关联公司、员工、客户和合作伙伴可在对第三人承担金钱给付等义务后就全部损失向您追偿。</p>
        <h3>9.4</h3>
        <p>在法律允许的情况下，「卒息常谈」对于与本服务条款有关或由本服务条款引起的任何间接的、惩罚性的、特殊的、派生的损失（包括业务损失、收益损失、利润损失、使用数据、商誉或其他经济利益的损失），不论是如何产生的，也不论是由对本服务条款的违约（包括违反保证）还是由侵权造成的，均不负有任何责任，即使事先已被告知此等损失的可能性。另外即使本服务条款规定的排他性救济没有达到其基本目的，也应排除「卒息常谈」对上述损失的责任。</p>
        <h2>10. 通知</h2>
        <h3>10.1</h3>
        <p>「卒息常谈」将通过您提供的有效联系方式的其中之一或其中若干向您送达各类通知，您有效的联系方式包括：
            o您应当按「卒息常谈」网站页面的提示准确完整地提供您的真实有效的联系方式（包括您的电子邮件地址、联系电话、联系地址等），以便「卒息常谈」或其他用户与您联系。
            o在您取得「卒息常谈」账号或使用服务时，您有可能会获得用于登陆「卒息常谈」网站接收站内信、系统消息、弹出消息、推送消息，该「卒息常谈」账号也将作为您的有效联系方式。</p>
        <h3>10.2</h3>
        <p>您了解并同意，您有义务保持您提供联系方式的真实性及有效性。联系方式发生变更的，您有义务及时更新有关信息，并保持可被联系的状态。</p>
        <h3>10.3</h3>
        <p>「卒息常谈」将通过本服务协议第10.1约定的有效联系方式向您发出通知。其中，以电子的方式发出的书面通知，包括但不限于在「卒息常谈」公告，向您提供的联系电话发送手机短信，向您提供的电子邮件地址发送电子邮件，向您的账号发送系统消息、站内信信息、弹出消息和推送消息，在发送成功后即视为送达；以纸质载体发出的书面通知，按照提供联系地址交邮后的第五个自然日即视为送达。</p>
        <h2>11. 适用法律与争议解决</h2>
        <h3>11.1</h3>
        <p>本服务协议的订立、生效、解释、修订、补充、终止、执行与争议解决均应适用中华人民共和国人民共和国法律。</p>
        <h3>11.2</h3>
        <p>如果您要投诉有关的服务，请在第一时间请通过app@wemoai.com与我们取得联系，我们将尝试解决您的投诉。您应当在相关事件发生后的12个月内提出投诉，您可以为您自己或代表其他人提出投诉，前提是您有该等人士的书面授权许可（除非该等人士缺乏民事行为能力或是已经死亡）。我们会尽快回复您的投诉，并与您讨论如何最好地解决您的投诉。</p>
        <h3>11.3</h3>
        <p>您因使用服务所产生和/或与服务有关的争议，由「卒息常谈」与您协商解决；协商不成时，任何一方均可向北京市海淀区人民法院提起诉讼。
            如您有其他问题，请通过app@wemoai.com与我们取得联系。谢谢。</p>
    </div>
)

const Privacy = () => (
    <div className='Protocol-container'>
        <h1>用户隐私保护政策</h1>
        <h2>创建时间：2023年6月<br />最近一次更新时间：2023年7月</h2>
        <p>微米创想科技（北京）有限公司（以下简称“卒息常谈”或“我们”）尊重并保护用户隐私，并严格遵循法律法规，竭力保障您的信息安全。鉴于此，我们制定本《用户隐私保护政策》（下称“本隐私政策”）并提醒您：本隐私政策适用于我们向您提供产品或服务时所有的个人信息处理活动，包括但不限于：卒息常谈移动App、H5页面、微信公众号、网页、小程序等线上平台（合称“卒息常谈服务”）。在您使用卒息常谈服务时，我们将按照本隐私政策收集、使用、共享和披露您的个人信息。请您特别查看我们如何收集、使用您的信息条款（包括但不限于您向我们提供健康生理信息后我们如何处理，详见本隐私政策第三条）。
            您知晓并确认您在注册时或首次登录时已经点击同意（或以类似的方式选择接受）本隐私政策的约定。如您不接受本隐私政策的任意内容，或者无法准确理解相关条款含义，请不要进行后续操作，可随时通过本隐私政策第十一条所载客服电话与我们联系。当您点击同意本隐私政策并开始使用我们提供的任一服务或您主动按服务指引向我们提供相关信息时，即表示您知悉和同意我们按本隐私政策来处理和使用您的个人信息。如您对本隐私政策有任何疑问，您可通过本隐私政策所载联系方式或通过卒息常谈客服与我们联系。
            本隐私政策将帮助您了解以下内容：</p>
        <h4>1、名词定义</h4>
        <h4>2、适用范围</h4>
        <h4>3、我们如何收集及使用您的个人信息</h4>
        <h4>4、我们如何使用Cookie</h4>
        <h4>5、我们如何共享、转让、公开披露您的个人信息</h4>
        <h4>6、我们如何存储您的个人信息</h4>
        <h4>7、我们如何保护您的个人信息</h4>
        <h4>8、您的个人信息权利</h4>
        <h4>9、我们如何保护未成年人的个人信息</h4>
        <h4>10、本隐私政策的修订</h4>
        <h4>11、您如何联系我们</h4>
        <h2>一、名词定义</h2>
        <p>（一）个人信息：以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。</p>
        <p>（二）敏感个人信息：一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹等信息，以及不满十四周岁未成年人的个人信息。</p>
        <h2>二、适用范围</h2>
        <p>（一）本隐私政策适用于我们向您提供的所有产品与服务，包括但不限于卒息常谈服务，您能使用的具体服务以您可操作的页面所载内容为准。如我们提供的某款产品或服务有单独的隐私政策或相应的用户服务协议中存在特殊约定，则该产品或服务的隐私政策或用户服务协议将优先适用。该款产品或服务隐私政策或用户服务协议未涵盖的内容，以本隐私政策内容为准。</p>
        <p>（二）本隐私政策不适用于第三方向您提供的服务。卒息常谈服务可能含有连接到第三方网站的链接，您可以选择是否访问第三方提供的链接、内容、产品和服务。我们会依据法律法规的要求采取必要的措施对相关网站进行审查（包括审查网站经营者的基本资质），但无法保证该等第三方的信息保护力度与措施，我们对该等第三方的信息保护不承担责任。我们建议您在向第三方提交个人信息之前，查看这些第三方的隐私保护政策，了解其如何处理您的信息，以便审慎决策。</p>
        <h2>三、我们如何收集及使用您的个人信息</h2>
        <p>我们仅会在您注册、登录及使用我们的服务的过程中出于本隐私政策下述目的收集和使用您的如下必要的个人信息。同时，您在本隐私政策中对我们使用您个人信息的授权具有独立性，不因您与我们签署的其他合同的任何条款无效而失效。我们会不定期重新取得您的授权，以保证您提供的信息为最新、最完整的个人信息。您可以选择不提供某一或某些个人信息，但这样可能使您无法使用卒息常谈与该等信息对应的部分或全部服务。
            对于其中可能涉及的敏感个人信息，我们以加粗的显著方式提醒您注意。如果您提供的个人信息非本人信息，您应确保您已获得本人的授权同意。</p>
        <p>（一）注册并登录</p>
        <p>1、您使用卒息常谈服务时，应首先注册为卒息常谈用户。为完成创建卒息常谈账号，及通知您使用我们服务中的服务状态，您需要提供手机号码；</p>
        <p>2、当您通过微信小程序登录或选择绑定微信账号时，我们会在获得您授权同意的前提下，从微信处获取口令。在登录健康管理服务时，需要进行二次登录，我们会收集您的手机号码、用户基本信息（昵称、头像、性别、生日）。</p>
        <p>3、我们收集该类信息是基于法律法规的相关要求，如您不提供前述信息，可能导致您无法成为卒息常谈用户并使用卒息常谈服务。</p>
        <p>（二）使用卒息常谈提供的服务</p>
        <p>1、当您使用症状自诊功能时，为了根据您的健康状况和症状向您提供咨询服务，您可以根据智能指导填写您的疾病、症状、肝肾功能、过敏史、用药信息、个人档案、体征、病史、是否怀孕（仅女性）、月经状况（仅女性）。如您想使用语音输入功能的，您需要开启麦克风权限，上述信息包含您的敏感个人信息，如果您拒绝开启上述权限或提供信息，将无法使用相应的功能，但不影响您使用卒息常谈的其他功能。</p>
        <p>2、使用小病找药功能时，为了根据您的健康状况和症状向您展示可能使用的药物，您可以根据智能指导填写您的疾病、症状、肝肾功能、过敏史、用药信息、个人档案、体症、病史、是否怀孕（仅女性）、月经状况（仅女性）。如您想使用语音输入功能的，您需要开启麦克风权限，上述信息包含您的敏感个人信息，如果您拒绝开启上述权限或提供信息，将无法使用相应的功能，但不影响您使用卒息常谈的其他功能。</p>
        <p>3、使用用药管家功能时，您可通过“拍药识方-用药指导”上传药盒图片或处方图片，我们可能会收集您的年龄、性别、生日、昵称/账户名、过敏史、肝肾功能以及处方信息（包括诊断信息和开药信息），以便我们向您提供用药指导的服务。同时，您可以在“用药提醒”功能中添加药品信息、用法用量、生活习惯、年龄/生日、性别、昵称/账户名、过敏史、肝肾功能信息，以便我们通知和提醒您用药。您需要开启相机（摄像头）权限或相册权限，以便于您通过拍照方式或相册上传图片方式上传药盒图片或处方图片。上述信息包含您的敏感个人信息，如果您拒绝开启上述权限或提供信息，将无法使用相应的功能，但不影响您使用卒息常谈的其他功能。</p>
        <p>4、使用防疫问答、疾病知识、药品知识、药品说明、健康问答功能时，您可以根据智能指导填写或输入您感兴趣的问题或话题，我们将向您展示相关问题的知识和说明。如您想使用语音输入功能的，您需要开启麦克风权限，上述信息包含您的敏感个人信息，如果您拒绝开启上述权限或提供信息，将无法使用相应的功能，但不影响您使用卒息常谈的其他功能。</p>
        <p>5、使用孕妇饮食功能时，我们可需要收集您的昵称、预产期。您也可以根据智能指导填写或输入您感兴趣的问题或话题，我们将向您展示相关问题的知识和说明。如您想使用语音输入功能的，您需要开启麦克风权限，上述信息包含您的敏感个人信息，如果您拒绝开启上述权限或提供信息，将无法使用相应的功能，但不影响您使用卒息常谈的其他功能。</p>
        <p>6、使用个人档案和病历功能时，为了记录您的基础信息，方便您查询、管理或分享病历，您可以根据智能指导同步或填写您的姓名（用户名）、性别、生日、生活习惯、病史、自诊记录、问药记录、病历、处方、检查检验报告、病症、体征信息、怀孕状态（仅女性）。您需要开启相机（摄像头）权限或相册权限，以便于您通过拍照方式或相册上传图片。上述信息包含您的敏感个人信息，如果您拒绝开启上述权限或提供信息，将无法使用相应的功能，但不影响您使用卒息常谈的其他功能。</p>
        <p>7、使用用药咨询功能时，为了便于医生提供用药咨询服务，您需要提供性别、年龄、体重、疾病、症状、用药信息、病历、病情、开药信息、用药报告信息、既往史、过敏史、肝肾功能、生活史、药物副作用、是否怀孕（仅女性）、是否经期（仅女性）。您需要开启相机（摄像头）权限或相册权限，以便于您通过拍照方式或相册上传图片。上述信息包含您的敏感个人信息，如果您拒绝开启上述权限或提供信息，将无法使用相应的功能，但不影响您使用卒息常谈的其他功能。</p>
        <p>8、使用快速问医功能时，为了便于医生提供用药咨询服务，您需要提供姓名、性别、出生日期、身份证号码（选填）、疾病、症状、用药、病历、检查检验单。当您需要添加其他就诊人信息时，请确保您已取得该个人信息主体的同意。特别地，如您添加的就诊人为未成年人，需要取得其父母或监护人的同意。上述信息包含您的敏感个人信息，如果您拒绝开启上述权限或提供信息，将无法使用相应的功能，但不影响您使用卒息常谈的其他功能。</p>
        <p>9、使用会员激活（健康管理业务）功能时，作为经激活方可使用的会员功能，首先需要您进行二次登录，获得您的手机号码、用户基本信息（昵称、头像、性别、生日）；为进一步向您提供健康管理服务，您可以根据智能指导填写您的民族、职业、婚育状况、文化程度、有无医学背景、工作单位、工作状态、居住地、居住状况、关注主题、慢性病史、过敏史、暴露史、家族史、疾病史、用药史、手术史、外伤史、输血史、膳食情况、体力活动及锻炼、吸烟情况、饮酒情况、精神和睡眠、生理指标信息、体检报告、线下就诊记录。上述信息包含您的敏感个人信息，如果您拒绝开启上述权限或提供信息，将无法使用相应的功能，但不影响您使用卒息常谈的其他功能。</p>
        <p>10、为了将您感兴趣的内容或信息展示给您，或在您搜索时向您展示您可能希望找到的内容，我们可能会收集您的用药报告信息进行数据分析，以为您提供个性化的用药信息分享或其他内容分享服务。如果您拒绝开启上述权限或提供信息，将无法使用相应的功能，但不影响您使用卒息常谈的其他功能。</p>
        <p>11、如您产生相关纠纷投诉或使用客户服务时，我们会收集您主动告知我们的信息。</p>
        <p>（四）您可以授权我们获取您的以下权限，您确认并同意开启这些权限即代表您授权我们可以访问相应的功能；若您未开启或开启后关闭了下述访问权限，则我们将无法访问该等功能，也无法为您提供上述与这些授权所对应的服务，但这不会对您使用我们其他服务产生影响：</p>
        <p>1、访问摄像头（或相机）：用于上传头像、病历信息；</p>
        <p>2、访问相册：用于上传头像、病历信息；</p>
        <p>3、麦克风：用于语音输入，免去手工键入文字；</p>
        <p>4、通知权限：用于消息推送提醒功能、推送天气；</p>
        <p>5、拨打电话权限：用于您联系所选择的医院；</p>
        <p>6、读取短信：用于自动填写手机验证码时；</p>
        <p>7、访问网络：用于与服务端通信；</p>
        <p>8、我们会收集您的IMEI（即设备序列号、安卓ID，记录设备唯一ID）、软件列表（微信、支付宝支付时，检测是否安装了支付宝软件）；</p>
        <p>（五）您充分知晓，以下情形中，我们收集和使用您的个人信息无需征得您的授权同意：</p>
        <p>1、为订立、履行个人作为一方当事人的合同所必需；</p>
        <p>2、为履行法定职责或者法定义务所必需；</p>
        <p>3、为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；</p>
        <p>4、为公共利益实施新闻报道、舆论监督的行为，在合理的范围内处理个人信息；</p>
        <p>5、依照本法规定在合理的范围内处理个人自行公开或者其他已经合法公开的个人信息；</p>
        <p>6、法律、行政法规规定的其他情形。</p>
        <p>（六）当我们要将您的个人信息用于本隐私政策未载明的其它用途时，或基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。</p>
        <h2>四、我们如何使用Cookie</h2>
        <p>为确保我们的产品、服务的正常运转、为您获得更轻松的访问体验，我们会在您的计算机或者移动设备上储存名为Cookie的小数据文件。Cookie通常包含标识符号、站点名称以及一些号码和字符。借助于Cookie，网站能够储存您的偏好及行为数据。
            我们不会将Cookie用于本隐私政策所述目的之外的任何用途，您可根据自己的偏好管理或删除Cookie。有关详情，请参见AboutCookie.org。您可以清除计算机上保存的所有的Cookie，大部分网络浏览器都设有阻止Cookie的功能。如果您这么操作，则需在每一次访问我们的网站时亲自更改用户设置。</p>
        <h2>五、我们如何共享、转让、公开披露您的个人信息</h2>
        <p>（一）与我们授权合作伙伴共享</p>
        <p>1、委托服务提供商：为了向您提供产品和服务，我们可能会委托第三方服务提供商帮助我们提供相关的运营和服务的支持。在此过程之中，基于该第三方服务提供商向您提供服务的目的我们会共享与之提供服务必要的个人信息，以实现我们产品和/或服务的功能。我们在与上述服务供应商合作时，没有也不会许可他们将我们与之共享的信息用于任何其他用途，服务提供商包括以下类型：</p>
        <p>（1）您在注册及使用我们的服务时候，我们会将您的手机号码提供给短信通道合作商以便通知您相关服务状态的通知，及经您许可后向您发送商业广告；</p>
        <p>（2）我们会将您的声纹信息提供给语音识别供应商，以便将您在使用语音服务时将语音转换为文字；</p>
        <p>（3）在卒息常谈微信小程序中，我们会将您的疾病管理计划信息、执行情况提供给第三方医院，以为您提供疾病管理咨询服务。<br />
            对我们与之共享个人信息合作方，我们会与其签署严格的保密协定，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。</p>
        <p>（二）我们如何转让您的个人信息</p>
        <p>我们不会将您的个人信息转让给任何公司、组织或个人，但以下情形除外：</p>
        <p>1、事先获得您明确的同意或授权；</p>
        <p>2、根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况进行提供；</p>
        <p>3、在涉及合并、收购、资产转让或类似的交易时，如果涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本隐私政策的约束，否则，我们将要求该公司、组织重新向您征求授权同意。</p>
        <p>（三）我们如何公开披露您的个人信息</p>
        <p>我们不会主动公开你未自行公开的信息，除非遵循国家法律法规规定或者获得你的同意。</p>
        <p>（四）您知悉以下情形中，我们共享、转让、披露您的个人信息无需征得您的授权同意：</p>
        <p>1、为订立、履行个人作为一方当事人的合同所必需；</p>
        <p>2、为履行法定职责或者法定义务所必需；</p>
        <p>3、为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；</p>
        <p>4、为公共利益实施新闻报道、舆论监督的行为，在合理的范围内处理个人信息；</p>
        <p>5、依照本法规定在合理的范围内处理个人自行公开或者其他已经合法公开的个人信息；</p>
        <p>6、法律、行政法规规定的其他情形。</p>
        <h2>六、我们如何存储您的个人信息</h2>
        <p>（一）存储地点</p>
        <p>原则上，我们在中华人民共和国境内收集和产生的您的个人信息，将存储在中国大陆境内。如果之后随着我们的业务发展需要向境外传输您的个人信息的，我们将按照法律规定在事前向您告知境外接收方的名称或者姓名、联系方式、处理目的、处理方式、个人信息的种类的事项后，另行取得您的单独同意。并遵循法律法规的其他相关要求，以确保您的个人信息受到充分保护。</p>
        <p>（二）存储期限</p>
        <p>我们会根据适用的法律、法规的要求，在达成本隐私政策所述目的所需的最短期限内保留您的个人信息。在您撤回授权同意、删除个人信息或注销您的卒息常谈账号时，我们将根据法律法规的要求删除或匿名化处理您的个人信息。</p>
        <h2>七、我们如何保护您的个人信息</h2>
        <p>我们已采取符合业界标准、合理可行的安全防护措施保护您提供的个人信息安全，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。</p>
        <p>1、我们将在合理的安全水平内使用各种安全保护措施以保障信息的安全。服务提供者使用加密技术、去标识化处理的手段来保护您的个人信息。</p>
        <p>2、我们建立专门的管理制度、流程和组织确保信息安全。我们严格限制访问信息的人员范围，要求他们遵守保密义务，并进行审查。</p>
        <p>安全提示：但请你理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全，我们将尽力确保你提供给我们的个人信息的安全性。</p>
        <h2>八、您的个人信息权利</h2>
        <p>按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己个人信息行使以下权利：</p>
        <p>（一）查询、复制、更正权</p>
        <p>如果您已经注册了卒息常谈账号，您可以通过卒息常谈App登录您的账号，以查询、复制、更正您的以下个人信息。</p>
        <p>1、账号信息：如果您希望访问或编辑您的账号中的个人资料信息、头像等，您可以访问我们的App端或H5页面或微信公众号相关页面进行此类操作。</p>
        <p>2、使用信息：您可以在我们App端或H5页面或微信公众号中查阅您的服务使用记录等。</p>
        <p>或者您可通过下方【联系我们】所述方式，提出查询、复制、更正我们保有的有关您的个人信息的要求，法律法规规定的例外情况除外。
            为了保护您的信息安全和我们的合法权益，未经我们同意您不得采用其他方式或授权第三方访问或收集您在我们平台保存的您的个人信息。</p>
        <p>（二）删除权</p>
        <p>除法律法规另有规定及本隐私政策另有约定外，在以下情形中，您可以向我们提出删除相应的个人信息的请求：</p>
        <p>1、处理目的已实现、无法实现或者为实现处理目的不再必要；</p>
        <p>2、当我们停止提供产品或者服务，或者保存期限已届满；</p>
        <p>3、您撤回同意；</p>
        <p>4、如果我们违反法律、行政法规或者违反约定处理个人信息；</p>
        <p>5、法律、行政法规规定的其他情形。</p>
        <p>若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其删除，除非法律法规另有规定，或者这些实体获得您的授权同意。
            除非法律法规另有规定外，当您从我们的服务中删除信息后，我们可能不会立即从备份系统中删除相应的信息，但是会在备份更新时删除这些信息，且您删除信息后，我们不会再继续使用您的个人信息。</p>
        <p>（三）改变授权同意的范围或撤回同意</p>
        <p>您可以通过关闭移动设备功能的方式撤销设备相关信息的授权。您可以联系我们客服来撤回您的授权。您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也将不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的同意或授权而开展的个人信息处理。</p>
        <p>（四）注销账号</p>
        <p>您可以在卒息常谈App或卒息常谈微信小程序中通过【注销账户号的操作】，申请注销您的卒息常谈账号，也可通过下方【联系我们】所述方式，提出注销账号的要求。
            当您符合约定的账号注销条件并注销您在我们平台的账号后，我们将不会再收集、使用或对外提供与该账号相关的个人信息，并对您该账号内的相关个人信息进行删除或匿名化处理，但法律法规另有规定的除外。</p>
        <p>（五）要求解释说明</p>
        <p>您有权要求我们对您的个人信息处理的规则进行解释说明，您可以通过本隐私政策【联系我们】章节说明的方式与我们联系，我们会在法律要求的时限内满足您的合法请求。</p>
        <p>（六）死者近亲属权利</p>
        <p>已故用户的近亲属为了自身的合法、正当利益，可以对已故用户的相关个人信息行使本章规定的查阅、复制、更正、删除的权利；已故用户生前另有安排的除外。已故用户的近亲属可以通过本隐私政策【联系我们】章节说明的方式与我们联系，我们会在法律要求的时限内满足您的合法请求。</p>
        <p>（七）响应您的上述请求</p>
        <p>1、如果您无法通过上述方式访问、更正、删除或撤销您的个人信息，或者您就我们收集、使用您信息有任何疑问，您都可以通过卒息常谈客服与我们联系。为保障安全，我们可能需要您提供书面请求，或以其他方式证明您的身份。我们将在收到您的反馈并验证您身份后15个工作日内答复您的相关请求。</p>
        <p>2、我们将尽合理商业努力，满足您对于个人信息的访问、更正、删除、撤销、管理的要求。但对于无端重复、需要过多技术手段、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。</p>
        <p>3、对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。</p>
        <p>4、请您理解，按照特定的法律法规要求，我们可能无法响应您的一些请求，包括与国家安全、公共利益、履行我们法定义务相关的，以及涉及商业秘密的情形。</p>
        <h2>九、我们如何保护未成年人的个人信息</h2>
        <p>我们非常重视对未成年人个人信息的保护。我们的产品、网站和服务主要面向成人，18周岁以下的未成年人不得创建自己的用户账号。
            对于经父母或监护人同意而收集未成年人个人数据的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用此数据。如果我们发现自己在未事先获得可证实的父母或监护人同意的情况下收集了未成年人的个人数据，则会设法尽快删除相关数据。
            如您对您所监护的未成年人的个人信息保护有相关疑问或权利请求时，请通过本政策第十一条中披露的联系方式与我们联系，我们在核实后会按照家长或监护人的要求对未成年人的个人信息进行删除或其他处理。</p>
        <h2>十、本隐私政策的修订</h2>
        <p>我们可能依据法律法规变更或业务发展适时修订本隐私政策。我们将在修订后通过显著页面通知、公告或其他合理、可行的方式通知您。如果您重新同意隐私政策并选择继续使用我们的服务，即表示同意本隐私政策的修订内容亦对您有约束力。
            本隐私政策所指的重大变更包括但不限于：</p>
        <p>1、我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息的类型、个人信息的使用方式等；</p>
        <p>2、我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有变更等；</p>
        <p>3、个人信息转移或公开的主要对象发生变化；</p>
        <p>4、您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
        <p>5、我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；</p>
        <p>6、个人信息安全影响评估报告表明存在高风险时。我们还会将本隐私政策的旧版本存档，供您查阅。</p>
        <h2>十一、您如何联系我们</h2>
        <p>（一）本平台运营主体及客服渠道</p>
        <p>本平台由如下主体运营：</p>
        <p>运营主体：北京左医科技有限公司</p>
        <p>注册地址：北京市西城区高粱桥路6号2层A区(T4)02A1</p>
        <p>如果您对本隐私政策有任何疑问、意见或建议，或您在使用卒息常谈服务时，就您的个人信息的收集、使用、共享、访问、更正、删除、撤销等相关事宜有任何意见或建议，或您在使用卒息常谈服务时遇到任何问题，您都可以通过以下联系方式与我们联系：</p>
        <p>电子邮件：［contact_us@zuoshouyisheng.com］</p>
        <p>电 话：［010-58301296］</p>
        <p>责 任 人：法务</p>
        <p>（二）特别提示</p>
        <p>我们提醒您，如您反馈的问题涉及您的个人信息，尤其是您的敏感个人信息，请您不要直接在邮件、电话或传真中进行透露，我们将会在确保您个人信息安全的情况下与您进一步沟通。</p>
        <p>（以上为用户隐私保护政策全部内容）</p>
    </div>
)

export default ({ type }) => {
    const navigate = useNavigate();
    const backPage = () => {
        navigate(-1)
    }

    return (
        <>
            <Title onClick={backPage} backStyle={{ marginLeft: 20 }} />
            {type === 'user' && <User />}
            {type === 'privacy' && <Privacy />}

        </>
    )
}