import { useEffect, useState } from 'react';
import { useNavigate, useParams, useLoaderData } from 'react-router-dom';
import {
    Button,
    Steps,
    Space,
    Input,
} from 'antd-mobile'
import {
    CheckCircleFill,
    ClockCircleFill,
    HandPayCircleOutline,
} from 'antd-mobile-icons'
import docktorPic from '../../assets/doctor.png';
import back from '../../assets/back.png'
import chatbg from '../../assets/chatbg.png';
import Title from '../../components/Title';
import Wraper from '../../components/Wraper';
import chatPic from '../../assets/chat-pic.png'
import toLabor from '../../assets/to-labor.png';

import ChatHelper from '../../utils/chat';
import { chatHref } from '../../constants';
import { appConfig } from '../../constants';
import './index.scss';

const config = appConfig();

function Chat(props) {
    const navigate = useNavigate();
    const loaderData = props.loaderData;
    const [disable, setDisable] = useState(false);
    const [answer, setAnswer] = useState("");
    const { id } = useParams();
    // const chat = new ChatHelper((v) => setAnswer(v));

    useEffect(() => {
        // chat.sendMessage('你好', () `=> {
        //     chat.sendMessage('我能吃什么？');
        // })
    }, [])

    const backPage = () => {
        props.onBack();
        // navigate('/', {
        //     replace: true,
        // })
    }
    return (
        <>
            <Wraper>
                <img className='Chat-to-labor' src={toLabor} onClick={() => window.location.href = config.customService} />
                <div className='Chat'>
                    {/* <img src={chatbg} className='Chat-bg' /> */}
                    <Title onClick={backPage} noTopMargin backStyle={{ left: 20, top: -2 }} />
                    {/* <img src={chatPic} className='Chat-doc-pic' /> */}
                    <div className='Chat-container'>
                        {/* <div className='Chat-illustrate'>
                            嗨，我是你的健康顾问。有任何健康问题可以随时与我沟通。<br />温馨提示：在做出任何决定之前，还应与医生联系。
                        </div> */}

                        <div className='Chat-messages'>
                            {/* {answer} */}
                            <iframe
                                src={config.chatHref + (loaderData[0] ? `&customPormptData=${loaderData[0].form}` : "")}
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    background: 'white'
                                }}
                                frameBorder="0">
                            </iframe>
                        </div>

                        {/* <Button className='Chat-edit' onClick={onEdit}>
                        {
                            disable ? "编辑" : "确认"
                        }
                    </Button> */}
                    </div>
                    {/* <div className='Chat-input-container'>
                    <Input className='Chat-input' placeholder='请输入您的问题' />
                </div> */}
                </div>
            </Wraper>
        </>
    );
}

export default Chat;
