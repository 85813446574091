import './fotter.scss';

function Footer({ children }) {
    return (
        <div className="Fotter">
            {children}
        </div>
    );
}

export default Footer;
