import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Button,
    Toast,
} from 'antd-mobile'
import {
    CheckCircleFill,
    ClockCircleFill,
    HandPayCircleOutline,
} from 'antd-mobile-icons'
import back from '../../assets/back.png'
import Title from '../../components/Title';
import FormItem from '../../components/FormItem';
import Wraper from '../../components/Wraper';

import getUserDoc from '../../services/getUserDoc';
import createUserDoc from '../../services/createUserDoc';
import saveUserBaseDoc from '../../services/saveUserBaseDoc';

import './index.scss';

const formMock = [
    {
        title: "姓名",
        type: "Input",
        key: 'name',
    },
    {
        title: "性别",
        type: "Picker",
        key: 'gender',
        columns: [[
            { label: '男', value: '1' },
            { label: '女', value: '0' },
        ]]
    },
    {
        title: "年龄",
        type: "Input",
        key: 'age',
    },
    // {
    //     title: "出生日期",
    //     type: "Calendar",
    //     key: 'birthday',
    // },
    {
        title: "联系电话",
        type: "Input",
        key: 'contact',
    },
    {
        title: "住院号",
        type: "Input",
        key: "InHospitalNumber",
    },
    {
        title: "门诊号",
        type: "Input",
        key: "OutpatientNumber",
    },
    {
        title: "手术日期",
        type: "Calendar",
        key: 'operationDay',
    },
    {
        title: "手术类型",
        type: "Input",
        key: "SurgicalType",
    },
    {
        title: "手术医院",
        type: "Input",
        key: "SurgicalHospital",
    },
    {
        title: "手术医生",
        type: "Input",
        key: "SurgicalDoctor"
    },
    {
        title: '过往病史',
        type: 'TextArea',
        key: "PastMedicalHistory",
    },
    {
        title: '药物过敏史',
        type: 'TextArea',
        key: "DrugAllergyHistory",
    },
]


function User() {
    const navigate = useNavigate();
    const [disable, setDisable] = useState(true);
    const { id } = useParams();
    const userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    if (!userInfo) navigate('/login', { replace: true })
    const [form, setForm] = useState(userInfo);

    const backPage = () => {
        navigate(-1)
    }

    const onEdit = async () => {
        if (disable) {
            setDisable(false);
            return;
        }
        const result = await saveUserBaseDoc(form);
        if (result.code === 200) {
            window.localStorage.setItem('userInfo', JSON.stringify({ ...form }));
            setDisable(true);
        } else {
            Toast.show({
                icon: 'fail',
                content: result.msg
            })
        }
    }
    // console.log(formMock, form, 23232323)
    return (
        <>
            <Wraper>
                <div className='User'>
                    <Title title="基础档案" onClick={backPage} />
                    <div className='User-form'>
                        {
                            formMock.map(item => {
                                return (
                                    <FormItem
                                        disabled={disable}
                                        {...item}
                                        getValue={v => setForm({ ...form, [item.key]: v })}
                                        defaultValue={() => {
                                            if (item.key === 'gender') {
                                                return form[item.key] === '1' ? '男' : '女';
                                            }
                                            return form[item.key] || "";
                                        }}
                                    />
                                )
                            })
                        }
                    </div>
                    <Button className='User-edit' onClick={onEdit}>
                        {
                            disable ? "编辑" : "确认"
                        }
                    </Button>
                </div>
            </Wraper>
        </>
    );
}

export default User;
