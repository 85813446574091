import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

function Wraper(props) {
    const navigate = useNavigate();
    const userInfo = window.localStorage.getItem('userInfo');
    if (!userInfo) {
        setTimeout(() => navigate('/login'))
    };
    return (
        <>
            {props.children}
        </>
    );
}

export default Wraper;
