import { Link, useNavigate, useParams } from 'react-router-dom';
import './index.scss';
import bg from '../../assets/loginbg.png';
import phone from '../../assets/login-phone.png';
import Title from '../../components/Title';
import { Button, Toast, Checkbox } from 'antd-mobile';
import { useState } from 'react';
import docktorPic from '../../assets/doctor.png';
import r from '../../utils/request';
import FormItem from '../../components/FormItem';
import { appConfig } from '../../constants';
const config = appConfig();
const request = r('wobi-wobi-sms-amuqavhorv.cn-beijing.fcapp.run');

const formMock = [
    {
        title: "姓名",
        type: "Input",
        key: 'name',
        require: true,
    },
    {
        title: "性别",
        type: "Picker",
        key: 'gender',
        require: true,
        columns: [[
            { label: '男', value: '1' },
            { label: '女', value: '0' },
        ]]
    },
    {
        title: "年龄",
        type: "Input",
        key: 'age',
        require: true,
    },
    // {
    //     title: "出生日期",
    //     type: "Calendar",
    //     key: 'birthday',
    //     require: true,
    // },
    {
        title: "联系电话",
        type: "Input",
        key: 'contact',
        require: true,
    },
    {
        title: "住院号",
        type: "Input",
        key: "InHospitalNumber",
    },
    {
        title: "门诊号",
        type: "Input",
        key: "OutpatientNumber",
    },
    {
        title: "手术日期",
        type: "Calendar",
        key: 'operationDay',
    },
    {
        title: "手术类型",
        type: "Input",
        key: "SurgicalType",
    },
    {
        title: "手术医院",
        type: "Input",
        key: "SurgicalHospital",
    },
    {
        title: "手术医生",
        type: "Input",
        key: "SurgicalDoctor"
    },
    {
        title: '过往病史',
        type: 'TextArea',
        key: "PastMedicalHistory",
    },
    {
        title: '药物过敏史',
        type: 'TextArea',
        key: "DrugAllergyHistory",
    },
]

function Login() {
    const [status, setStatus] = useState('bind');
    const [phoneValue, setPhoneValue] = useState('');
    const [codeValue, setCodeValue] = useState('');
    const navigate = useNavigate();
    const [bizId, setBizId] = useState('');
    const [confirmProtocol, setConfirmProtocol] = useState(false);
    const form = {};

    const backPage = () => {
        setStatus('bind');
    }
    const bindWX = () => {
        setStatus('bind');
    }
    const checkPhone = async () => {
        if (!confirmProtocol) {
            Toast.show({
                icon: 'fail',
                content: <div style={{ textAlign: 'center' }}>请勾选并同意用户协议与隐私协议</div>,
            })
            return;
        }
        if (!phoneValue || !codeValue) {
            Toast.show({
                icon: 'fail',
                content: '请填写正确的手机号验证码',
            })
            return;
        }
        try {
            const result = await request.post('/wobi/login/check', {
                phone: phoneValue,
                code: codeValue,
                bizId,
            })
            if (result.data.code === 200 && !result.data.data) {
                setStatus('info');
            } else if (result.data.code === 200 && result.data.data) {
                const userInfo = JSON.parse(result.data.data.baseInfo);
                userInfo.id = result.data.data.UserId;
                window.localStorage.setItem('userInfo', JSON.stringify(userInfo))
                navigate('/');
            } else {
                Toast.show({
                    icon: 'fail',
                    content: result.data.msg || "网络加载失败,请稍后重试",
                })
            }
        } catch (e) {
            Toast.show({
                icon: 'fail',
                content: e.message || "网络加载失败,请稍后重试",
            })
        }
    }
    const setInfo = async () => {
        try {
            for (let i = 0; i < formMock.length; i++) {
                const item = formMock[i];
                if (item.require && !form[item.key]) {
                    Toast.show({
                        content: `请填写正确的${item.title}`,
                    })
                    return;
                }
            }
            form.phone = phoneValue;
            const { data } = await request.post('/wobi/login', { ...form, appId: config.appId });
            if (data.code === 200) {
                window.localStorage.setItem('userInfo', JSON.stringify({ ...form, id: data.data.UserId }));
                navigate('/');
            } else {
                Toast.show({
                    icon: 'fail',
                    content: data.msg,
                })
            }
        } catch (e) {
            Toast.show({
                icon: 'fail',
                content: '发送成功!',
            })
        }
    }

    const sendSms = async () => {
        const result = await request.post('/wobi/login/sendSMS', {
            phone: phoneValue,
        })
        if (result.data.code === 200) {
            setBizId(result.data.data.bizId);
            Toast.show({
                icon: 'success',
                content: '发送成功!',
            });
        } else {
            Toast.show({
                icon: 'fail',
                content: '短信发送失败',
            })
        }
    }

    const switchProtocol = (e) => {
        setConfirmProtocol(e);
    }

    return (
        <div className='Login'>
            <img src={bg} className='Login-bg' />
            {
                status === 'welcome' && (
                    <div className='Login-welcome'>
                        <div className='Login-welcome-hello'>HELLO</div>
                        <div className='Login-welcome-title'>欢迎使用 <a>{config.title}</a></div>
                        <div className='Login-welcome-content'>我是你的随诊小助手，请注册登陆后与我互动交流！</div>
                        <Button className='Login-welcome-btn' onClick={bindWX}>
                            <img src={phone} className='Login-welcome-icon' />
                            手机号码登录
                        </Button>
                    </div>
                )
            }
            {
                status === 'bind' && (
                    <div className='Login-bindphone'>
                        <div className='Login-bind-title'>
                            手机号登录
                        </div>
                        <div className='Login-bind-item'>
                            <FormItem
                                title="手机号"
                                type="Input"
                                getValue={(v) => setPhoneValue(v)}
                            />
                            <FormItem
                                title="获取验证码"
                                type="SmsCode"
                                phone={phoneValue}
                                code={codeValue}
                                getValue={v => setCodeValue(v)}
                                sendSms={sendSms}
                            />
                        </div>
                        <div className='Login-protocol-switch-container'>
                            <Checkbox onChange={switchProtocol}>登录即注册，并代表您同意 <Link to="/protocol/user">用户服务协议</Link>、<Link to="/protocol/privacy">隐私协议</Link></Checkbox>
                        </div>
                        <Button className='Login-bind-btn' block onClick={checkPhone}>
                            确认
                        </Button>
                    </div>
                )
            }
            {
                status === 'info' && (
                    <>
                        <Title backStyle={{ left: 20 }} style={{ marginBottom: -4 }} onClick={backPage} />
                        <div className='Login-banner'>
                            <img src={config.pic} className='Login-doctor-pic' />
                            <div className='Login-doctor-talk'>
                                您好，我是{config.name}。<br />
                                请认真填写下方表格，以便更好的了解你的健康情况。
                            </div>
                        </div>
                        <div className='Login-container'>
                            <div className='Login-form'>
                                {
                                    formMock.map(item => (
                                        <FormItem
                                            getValue={(value) => {
                                                form[item.key] = value
                                            }}
                                            {...item}
                                        />
                                    ))
                                }
                            </div>
                            <Button block className='Login-edit' onClick={setInfo}>
                                确认
                            </Button>
                        </div>
                    </>
                )
            }
        </div>
    )
}

export default Login