import { useEffect, useState } from 'react';
import { useNavigate, useParams, useLoaderData } from 'react-router-dom';
import {
    Button,
    Toast,
} from 'antd-mobile'
import {
    CheckCircleFill,
    ClockCircleFill,
    HandPayCircleOutline,
} from 'antd-mobile-icons'
import back from '../../assets/back.png'
import Title from '../../components/Title';
import FormItem from '../../components/FormItem';
import Wraper from '../../components/Wraper';
import createPromptData from '../../services/createPromptData';

import getPromptData from '../../utils/getPromptData';

import './index.scss';

const formMock = [
    {
        title: "脑卒中类型",
        key: "脑卒中类型",
        type: "Picker",
        columns: [[
            { label: '蛛网膜下腔出血', value: '蛛网膜下腔出血' },
            { label: '脑出血', value: '脑出血' },
            { label: '脑血栓形成', value: '脑血栓形成' },
            { label: '脑栓塞', value: '脑栓塞' },
            { label: '腔隙性梗死', value: '腔隙性梗死' },
            { label: '分水岭脑梗死', value: '分水岭脑梗死' },
            { label: '未分类脑卒中', value: '未分类脑卒中' },
        ]]
    },
    {
        title: "脑卒中部位",
        key: "脑卒中部位",
        type: "Picker",
        columns: [[
            { label: '大脑半球左侧', value: '大脑半球左侧' },
            { label: '大脑半球右侧', value: '大脑半球右侧' },
            { label: '脑干', value: '脑干' },
            { label: '小脑', value: '小脑' },
            { label: '不确定', value: '不确定' },
        ]]
    },
    {
        title: "目前症状",
        key: "目前症状",
        type: "Picker",
        columns: [[
            { label: '无', value: '无' },
            { label: '嘴、眼歪斜', value: '嘴、眼歪斜' },
            { label: '半身不遂', value: '半身不遂' },
            { label: '舌强言蹇', value: '舌强言蹇' },
            { label: '智力障碍', value: '智力障碍' },
            { label: '其他症状', value: '其他症状' },
        ]]
    },
    {
        title: "个人病史",
        key: "个人病史",
        type: "Picker",
        columns: [[
            { label: '冠心病', value: '冠心病' },
            { label: '高血压', value: '高血压' },
            { label: '高脂血症', value: '高脂血症' },
            { label: '糖尿病', value: '糖尿病' },
            { label: '无', value: '无' },
        ]]
    },
    {
        title: "脑卒中并发症情况",
        key: "脑卒中并发症情况",
        type: "Picker",
        columns: [[
            { label: '无', value: '无' },
            { label: '褥疮', value: '褥疮' },
            { label: '呼吸道感染', value: '呼吸道感染' },
            { label: '泌尿道感染', value: '泌尿道感染' },
            { label: '深静脉炎', value: '深静脉炎' },
            { label: '其他', value: '其他' },
        ]]
    },
    {
        title: "新发卒中症状",
        key: "新发卒中症状",
        type: "Picker",
        columns: [[
            { label: '无症状', value: '无症状' },
            { label: '构音障碍', value: '构音障碍' },
            { label: '失语', value: '失语' },
            { label: '面瘫', value: '面瘫' },
            { label: '感觉障碍', value: '感觉障碍' },
            { label: '左侧肢体瘫痪', value: '左侧肢体瘫痪' },
            { label: '共济失调', value: '共济失调' },
            { label: '昏迷', value: '昏迷' },
            { label: '右侧肢体瘫痪', value: '右侧肢体瘫痪' },
            { label: '头痛', value: '头痛' },
            { label: '呕吐', value: '呕吐' },
            { label: '意识障碍', value: '意识障碍' },
            { label: '眩晕', value: '眩晕' },
            { label: '癫痫', value: '癫痫' },
        ]]
    },
    {
        title: "康复治疗的方式",
        key: "康复治疗的方式",
        type: "Picker",
        columns: [[
            { label: '无', value: '无' },
            { label: '按摩', value: '按摩' },
            { label: '针灸', value: '针灸' },
            { label: '运动训练', value: '运动训练' },
            { label: '其他方式', value: '其他方式' },
        ]]
    },
    {
        title: '用药情况',
        key: "用药情况",
        type: "Input",
    },
    {
        title: '生活是否自理',
        key: "生活是否自理",
        type: "Input",
    },
    {
        title: '血压',
        key: "血压",
        type: "Input",
    },
    {
        title: '空腹血糖',
        key: "空腹血糖",
        type: "Input",
    },
]

function FollowDetail(props) {
    const navigate = useNavigate();
    // JSON.parse(loaderData[params.id].form)
    const [loaderData, setLoaderData] = useState([]);
    const params = useParams();
    const [disable, setDisable] = useState(true);
    const { id } = useParams();
    const [form, setForm] = useState({});

    const backPage = () => {
        navigate(-1)
    }

    const onEdit = async () => {
        const id = isNaN(Number(params.id)) ? 1 : Number(params.id) + 1;
        if (disable) {
            setDisable(false);
        } else {
            setDisable(true);
            const userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
            const result = await createPromptData(userInfo.id + `-${id}`, JSON.stringify(form));
            if (result.code === 200) {
                Toast.show({
                    type: 'success',
                    content: '保存成功!'
                })
            } else {
                Toast.show({
                    type: 'fail',
                    content: result.msg,
                })
            }
        }
    }

    useEffect(() => {
        getPromptData(navigate, (data) => {
            setLoaderData(data);
            setForm(JSON.parse(data[params.id].form))
        })
    }, [])

    return (
        <>
            <Wraper>
                <div className='FollowDetail'>
                    <Title title="随诊记录" onClick={backPage} />
                    <div className='FollowDetail-form'>
                        {
                            formMock.map(item => {
                                return (
                                    <FormItem
                                        key={item.key}
                                        disabled={disable}
                                        {...item}
                                        getValue={v => {
                                            const newData = { ...form };
                                            newData[item.title] = v;
                                            setForm(newData)
                                        }}
                                        defaultValue={() => {
                                            if (item.key === 'gender') {
                                                return form[item.key] === '1' ? '男' : '女';
                                            }
                                            return form[item.key] || "";
                                        }}
                                    />
                                )
                            })
                        }
                    </div>
                    <Button className='FollowDetail-edit' onClick={onEdit}>
                        {
                            disable ? "编辑" : "确认"
                        }
                    </Button>
                </div>
            </Wraper>
        </>
    );
}

export default FollowDetail;
