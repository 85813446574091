import r from "../utils/request";
const request = r('wobi-wobi-sms-amuqavhorv.cn-beijing.fcapp.run');

export default async function saveUserBaseDoc(form) {
    try {
        const result = await request.post('/wobi/user/update', {
            phone: form.phone,
            id: form.id,
            baseInfo: JSON.stringify(form),
        });
        return result.data;
    } catch (e) {
        console.log(e)
        return {
            code: 204,
            msg: '更新用户档案失败'
        }
    }
}
