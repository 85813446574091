import "./title.scss";
import back from '../../assets/back.png'
import backnew from '../../assets/backnew.png'

function CustomLink({ title, onClick, noTopMargin, style, backStyle }) {
    const styles = {
        ...style,
    }
    if (noTopMargin) {
        styles.marginTop = 0
    }
    return (
        <div className='title' style={styles}>
            {title ? title : null}
            <img style={backStyle || {}} src={backnew} onClick={onClick} className="back-btn" />
        </div>
    );
}

export default CustomLink;