/* eslint-disable*/

import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Button, Divider, SpinLoading, Empty } from 'antd-mobile'
import { useNavigate, useParams, useLoaderData } from 'react-router-dom';

import Chat from '../chat';

import './index.scss';
import docktorPic from '../../assets/doctor.png';

import bg from '../../assets/bg.png';
import phone from '../../assets/phone.png';
import user from '../../assets/user.png';
import pushbg from '../../assets/pushbg.png';

import Wraper from '../../components/Wraper';
import Fotter from '../../components/Fotter';
import Popup from '../../components/Popup';
import DoctorOverview from '../../components/DoctorOverview';
import UserOverview from '../../components/UserOverview';

import { appConfig } from '../../constants';
import getPromptData from '../../utils/getPromptData';

import r from '../../utils/request';
const request = r('wobi-wobi-sms-amuqavhorv.cn-beijing.fcapp.run');
const config = appConfig();

function Doctor(props) {
  const [loaderData, setLoaderData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [userVisible, setUserVisible] = useState(false);
  const [article, setArticle] = useState([]);
  const navigate = useNavigate();

  const userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
  if (!userInfo) {
    navigate('/login', { redirect: true });
  };

  const getArticle = async () => {
    if (!userInfo) return
    setLoading(true)
    try {
      const result = await request.post('/wobi/article/all', { appId: config.appId });
      if (result.data.code === 200 && result.data.data) {
        result.data.data.sort((a, b) => a.index - b.index);
        result.data.data.forEach(item => {
          if (item.paragraph) {
            item.paragraph = JSON.parse(item.paragraph);
          }
          const date = new Date(item.createAt);
          item.date = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
        })
        setArticle(result.data.data);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }

  const toDetail = async (item) => {
    window.location.href = item.link;
    // navigate(`/push?article=${JSON.stringify(item)}`);
  }

  const checkPromptExpire = async () => {
    // const userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    const now = Date.now();
    const week = 1000 * 60 * 60 * 24 * 7;
    const month = week * 4;
    const threeMonth = week * 12;
    const operationDate = new Date((userInfo.operationDay || "").replace(/-/g, "/"))
    if ((!loaderData || !loaderData.length) && (now - operationDate.getTime()) >= week) {
      navigate('/doc-write')
      return;
    };
    if (loaderData && loaderData.length === 1) {
      const time = now - loaderData[0].createAt;
      if (time > month) {
        navigate('/doc-write');
        return;
      };
    }
    if (loaderData && loaderData.length === 2) {
      const time = now - loaderData[0].createAt;
      if (time > threeMonth) {
        navigate('/doc-write');
        return;
      };
    }
    // navigate('/doc-write');
    setShowChat(true);
  }

  useEffect(() => {
    getPromptData(navigate, (data) => {
      setLoaderData(data)
    });
    getArticle();
  }, [])

  return (
    <>
      <Wraper>
        <div className="Doctor">
          <img className='bg' src={bg} />
          <img className='Doctor-pic' src={config.pic} />
          <div className='Doctor-info-container'>
            <div className='Doctor-title'>
              {config.name}
              <img src={phone} className='Doctor-phone-icon' />
            </div>
            <div className='Doctor-info'>
              {
                config.info.map(item => (
                  <div>{item}</div>
                ))
              }
            </div>
            <div className='Doctor-detail-btn' onClick={() => setVisible(true)}>
          查看更多 >
            </div>
          </div>

          <div className='Doctor-push-container'>
            <div className='Doctor-push-content'>
              <div className='Doctor-push-title'>
                专家推送
              </div>
              <div className='Doctor-push-title-descript'>
                专家为您整理的专属推送
              </div>

              <div className='Doctor-push-list-container'>
                <div className='Doctor-push-list'>
                  {
                    article.length ? article.map((item, index) => (
                      <div style={{ marginBottom: index === article.length - 1 ? 40 : 16 }} className='Doctor-push-item' key={item} onClick={() => toDetail(item)}>
                        <div className='Doctor-push-pic-container'><img className='Doctor-push-pic' src={item.imageUrl} /></div>
                        <div className="Doctor-push-texts">
                          <div className="Doctor-push-title">{item.articleTitle}</div>
                          <div className="Doctor-push-text">{item.articleInfo}</div>
                          {/* <div className="Doctor-push-info">
                          <div className='Doctor-push-info-left'>{item.date}</div>
                          <div className='Doctor-push-info-right'>孙勇医生推送</div>
                        </div> */}
                        </div>
                      </div>
                    )) : loading ? <div className='Doctor-push-loading'><SpinLoading /></div> : <div className='Doctor-push-loading'><Empty description="暂无推送" /></div>
                  }
                </div>
              </div>
            </div>
          </div>

          <Fotter>
            <div className='Doctor-footer'>
              <Button onClick={checkPromptExpire} block>谈一谈</Button>
              <img src={user} className='Doctor-fotter-icon' onClick={() => setUserVisible(true)} />
            </div>
          </Fotter>
        </div>
        <DoctorOverview visible={visible} onClose={() => setVisible(false)} />
        <UserOverview visible={userVisible} onClose={() => setUserVisible(false)} />
        <div className={`Doctor-chat-container ${showChat ? 'active' : ''}`}>
          <Chat onBack={() => setShowChat(false)} loaderData={loaderData} />
        </div>
      </Wraper>
    </>
  );
}

export default Doctor;
