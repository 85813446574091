import { Link } from 'react-router-dom';
import "./link.scss";

function CustomLink({ onClick, to, children }) {
    return (
        <Link
            to={to}
            onClick={onClick}
            className="custom-link"
        >
            {children}
        </Link>
    );
}

export default CustomLink;