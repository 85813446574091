import { useNavigate, useParams } from 'react-router-dom';
import './index.scss';
import pushbg from '../../assets/pushbg.png';
import Title from '../../components/Title';
import Wraper from '../../components/Wraper';

const mock = [
    {
        content: '10月29日是世界卒中日，《健康连云港》栏目呼吁重视预防脑卒中。如何预防？先要了解什么是脑卒中？脑卒中有什么危害？如何识别脑卒中？突发脑卒中如何处理？近期，《健康连云港》栏目邀请了连云港市第一人民医院神经外科常务副主任兼神经介入科主任孙勇走进直播间，进行了主题为：脑卒中的预防与急救知识讲座。记者进行了梳理。',
    },
    {
        img: pushbg,
    },
    {
        title: '嘉宾简介：',
        content: '孙勇，医学博士，主任医师，硕士生导师。连云港市脑科医院党总支副书记，连云港市第一人民医院神经外科常务副主任兼神经介入科主任。江苏省“333高层次人才”、连云港市“521高层次人才”培养对象。连云港市卫计委“十三五”医学领军人才、创新团队带头人。中国老年医学学会脑卒中分会委员、江苏省卒中学会理事，连云港市卒中学会常务理事、秘书长，连云港市医学会脑卒中分会副主任委员。从事临床、教学、科研工作20余年，致力于脑血管病的临床研究，以第一作者、通讯作者发表SCI及中华论文12篇。主持省市级科研课题6项，主持高校教学课题1项。获连云港市科技进步二等奖1项，省新技术引进二等奖1项，市新技术引进一等奖、二等奖各1项。',
    },
    {
        title: '什么是脑卒中？',
        content: `
        脑卒中，俗称脑中风，又叫脑血管意外。分为：
        缺血性卒中（脑梗死、脑梗塞）
        出血性卒中（脑出血、脑溢血）
        缺血性脑卒中：是由于脑的供血动脉狭窄或闭塞，脑供血不足导致的脑组织坏死的总称，又叫脑梗死。
        出血性脑卒中：又称为颅内出血，就是人们常说的脑出血或脑溢血，蛛网膜下腔出血也属于这一类。
        出血性卒中的原因多为：高血压、颅内动脉瘤或血管畸形破裂引起，出血性脑卒中虽然发病率低于缺血性脑卒中，但其死亡率和病残率均高于缺血性脑卒中。`
    },
    {
        title: '脑卒中有什么危害？',
        content: `脑卒中自2015年以来已成为我国第一位的疾病致死及致残原因，是严重危害我国国民健康的重大慢性非传染性疾病。
      《中国脑卒中防治报告2020》指出脑卒中是严重危害人民群众健康的重大疾病，是中国成人居民致死、致残的首位病因。
      全球疾病负担研究数据显示，中国是最大的发展中国家，人口占世界总人口的五分之一，其脑卒中现患人数高居世界首位。`
    },
    {
        title: '脑卒中发病率、致残率、致死率高吗？',
        content: `其实脑卒中有五高的特点：
        1）高发病率：
        出血性脑卒中，45/10万，2250/500万
        缺血性脑卒中，145/10万，7250/500万
        连云港市，每年1万人发生脑卒中。
        世界卫生组织（WHO）统计，全球每6个人中就有1人可能患脑卒中，每6秒就有1人死于脑卒中，每6秒就有1人因脑卒中而永久致残。中国人总体脑卒中终生发病风险为39.9％ ，位居全球首位，这意味着中国居民一生中每５个人大约会有２个人患脑卒中。脑卒中现患人数高居世界首位。
        2）患病率：
        40岁及以上人群患病率，2.58%，连云港市现患和曾患脑卒中13万。
        3）高致残率
        脑卒中幸存患者中75%左右丧失劳动能力，40%重度残疾。
        4）高复发率：
        脑卒中具有高复发的特点，中国脑血管病大数据平台数据显示：脑卒中存活患者发病1年内卒中复发率为7.48%。
        5）高死亡率：
        高死亡率：154/10万，连云港市，因脑卒中年死亡7700人。`
    },
    {
        title: `如何识别脑卒中？`,
        content: `一旦脑卒中（脑中风）的症状出现，您要学会快速识别：教大家一个快速识别的方法：“中风120”。这里的：1指的是看：看看一张脸，是否对称，有没有一侧脸出现眼歪、嘴歪。2指的是查-- 把两只胳膊，平行举起，有没有一边胳膊没力气、往下垂。0指的是（聆）听语言：有没有说话不清楚了或是不能理解他人的语言。如果出现3项中的任意一项，立刻打电话呼叫急救“120”，哪怕是深夜，哪怕子女不在身边，您都要学会为自己争取宝贵的抢救时间。请您牢记，脑卒中的最佳治疗时机是发病3小时以内，每延迟1分钟，就有190万个脑细胞死亡。每延迟10分钟，寿命减少一周。这个还指的是最终得到有效治疗的情况。如果延误时间太长，则可能失去救治时机。`
    },
    {
        title: `突发脑卒中如何处理？`,
        content: `如家人突然出现言语不清、一侧肢体麻木和/或无力、饮水呛咳、眩晕、剧烈头痛等症状，应考虑有脑卒中可能，不要惊慌失措，立即到医院就诊。救治越早，后遗症风险越低。
        如病人病情严重，或迅速进入昏迷，应先将病人平抬至床上，头部垫一低枕，并将头侧向一边；取下假牙，及时清除口鼻中的呕吐物及痰液，防止窒息；解开衣领，保持呼吸道的通畅；若有抽搐，可将小毛巾垫于口中，防止舌被咬伤；千万不要企图唤醒病人而摇动其身体和头部。同时，要及时联系120救护车辆将病人送往医院。
        在运送病人到医院的途中，要保护好病人。应把病人平托起来使其在车上躺平；如无急救车，可用平板三轮车护送病人；沿途要有专门人员保护病人的头部，避免头部发生剧烈摇晃和震动；头的位置要偏向一侧，便于呕吐物从口腔中流出，以免误入气管内发生窒息；如病人神志尚清楚，要多给以劝说和安慰。`
    },
    {
        title: `脑卒中的急救关键？`,
        content: `急性缺血性脑卒中：发病4.5小时内，可以药物溶栓！发病6小时内，最长24小时内，可以手术取栓！越早就诊越好！血流再通前，每分钟死亡190万个脑细胞！时间就是大脑！
        需要强调的是，无论溶栓还是取栓手术，都有一定的医疗风险与意外，但不可因噎废食，一定要相信医学，及早救治！
        目前连云港市二级以上医院都建立了完善的卒中中心与卒中急救绿色通道，我们连云港市第一人民医院卒中中心目前在通灌院区，7*24小时卒中急诊救治，年溶栓量360余例，取栓手术量100余例，综合诊疗救治水平在全国500多家高级卒中中心中稳定排名在前80左右。`
    },
    {
        title: `如何预防？`,
        content: `中国脑卒中防治报告2020：脑卒中作为一种可防可控的疾病，早期筛查、积极干预效果显著。其实脑卒中患者有三次逆转命运的机会。`
    },
    {
        title: `机会一：预防期`,
        content: `脑卒中发生前有一个危险因素长期作用的时期，这个过程可达几年，甚至十几年。
        例如动脉粥样化导致的脑梗死，大致需要三个阶段：1.动脉内膜损伤和增厚；2.动脉粥样硬化斑块形成；3.动脉狭窄和斑块破裂形成血栓。
        动脉粥样硬化斑块破裂可引起血小板聚集，同时产生附壁血栓落后被血流冲到远端堵塞动脉形成脑梗死。
        另外，动脉粥样硬化斑块在低密度脂蛋白沉积下，逐渐增大，最终导致动脉狭窄直至闭塞也形成脑梗死。
        脑出血也是因长期高血压，脑内的小动脉管壁发生玻璃样变性和纤维样坏死，甚至形成微动脉瘤或夹层动脉瘤，当血压骤升和剧烈波动，则引起动脉破裂出血。`
    },
    {
        title: `机会二：急救期`,
        content: `在脑卒中发生期，疾病往往突发，让病人和家属卒不及防，最易错失治疗时机。
        如果在发病后1-2小时内不能及时赶到医院，不留给医生足够的治疗时间，可能会超过4.5小时的时间窗，医生将不得不放弃溶栓治疗，因为此时出血的风险将会增大。
        如果这样，非常不幸，病人又与第二次可挽救命运的机会失之交臂。`
    },
    {
        title: `机会三：康复期`,
        content: `脑卒中后常有肢体无力和肢体瘫痪，然而，目前国内外却没有脑卒中后神经功能恢复有确切疗效的药物。
        脑卒中康复期治疗是国内外指南推荐的治疗方法。因为康复治疗的有效时间窗口仅有6个月，需要患者和家属的密切配合。切记一定不能错过最佳窗口期。`
    },
    {
        title: `日常生活中，家人需注意什么？`,
        content: `建立群众“自己是健康第一责任人”的理念。明确“脑卒中是可防可控可治”的疾病。养成良好的生活习惯。戒烟限酒。早睡早起。但不主张老年人过早起床锻炼。均衡饮食。低盐低脂；高蛋白、高不饱和脂肪酸、高维生素。服药依从性：高血压、高血糖、高血脂的控制。`
    },
    // { title: `《健康连云港》栏目播出平台：` },
    // { title: '连云港FM90.2' },
    // { title: '连云港手机台' },
    // { title: '播出时间：每周二17:30-17:55' },
];

function Push() {
    const navigate = useNavigate();
    const qs = new URLSearchParams(window.location.search);
    const article = JSON.parse(qs.get('article') || "{}");
    console.log(article, 23232323)

    const backPage = () => {
        navigate(-1)
    }

    return (
        <Wraper>
            <div className='Push'>
                <Title backStyle={{ top: 8, left: 20 }} onClick={backPage} />
                <div className='Push-container'>
                    <div className='Push-main-title'>{article.articleTitle}</div>
                    <div className='Push-info'>时间：{article.date}</div>
                    <div className='Push-content'>
                        {(article.paragraph || []).map(item => (
                            <div className='Push-paragraph' key={item.ArticleId}>
                                {item.title && <div className="Push-paragraph-title">{item.title}</div>}
                                {item.content && <div className="Push-paragraph-content">{item.content}</div>}
                                {item.pic && item.pic.indexOf('.mp4') > -1 ? <video controls className="Push-paragraph-image" src={item.pic}></video> : <img className="Push-paragraph-image" src={item.pic} />}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </Wraper>
    );
}

export default Push;