import getPromptData from '../services/getPromptData';

const getPromptDataLoader = async (navigate, cb) => {
    const userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    if (!userInfo) {
        navigate('/login', { redirect: true });
        return;
    };
    const result1 = await getPromptData(`${userInfo.id}-1`);
    const result2 = await getPromptData(`${userInfo.id}-2`);
    const result = [];
    if (result1.code === 200 && result1.data) {
        result.push(result1.data);
        if (result2.code === 200 && result2.data) {
            result.push(result2.data);
        }
    }
    window.localStorage.setItem('promptData', JSON.stringify(result));
    return cb(result)
    // throw new Error(result.msg);
}

export default getPromptDataLoader