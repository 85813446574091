import r from "../utils/request";
const request = r('wobi-wobi-sms-amuqavhorv.cn-beijing.fcapp.run');

export default async function getData(uid) {
    try {
        const result = await request.post('/wobi/user/getPromptData', { uid });
        return result.data;
    } catch (e) {
        console.log(e)
        return {
            code: 204,
            msg: '获取用户档案失败'
        }
    }
}
