import r from "../utils/request";
const request = r('wobi-wobi-sms-amuqavhorv.cn-beijing.fcapp.run');

export default async function createUserDoc(uid, form) {
    try {
        const result = await request.post('/wobi/userdoc/create', { uid, form });
        return result.data;
    } catch (e) {
        console.log(e)
        return {
            code: 204,
            msg: '创建用户档案失败'
        }
    }
}
