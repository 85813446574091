import { Divider, List } from 'antd-mobile'
import { Link, useNavigate } from 'react-router-dom'
import './UserOverview.scss';
import docktorPic from '../../assets/doctor.png';
import phone from '../../assets/phone.png';
import down from '../../assets/down.png';
import Popup from '../Popup';

import defaultUser from '../../assets/default-user.jpg';

import icon3 from "../../assets/icon3.png";
import icon2 from "../../assets/icon2.png";
import icon1 from "../../assets/icon1.png";

function UserOverview({ visible, onClose }) {
    const navigate = useNavigate();
    const toSuifang = () => { console.log('suifang') }
    const toDangan = () => { console.log('dangan') }
    // let age = null;
    const userInfo = JSON.parse(window.localStorage.getItem('userInfo') || "{}");
    // if (userInfo.birthday) {
    //     const userDate = new Date(userInfo.birthday.replace(/-/g, "/"));
    //     const now = Date.now();
    //     const diff = now - userDate.getTime();
    //     age = Math.floor(diff / 1000 / 60 / 60 / 24 / 365);
    // }

    const logout = () => {
        window.localStorage.clear();
        navigate('/login', {
            replace: true
        })
    }

    return (
        <Popup visible={visible} onClose={onClose}>
            <div className='UserOverview-detail'>
                <div className='UserOverview-detail-info'>
                    <div className='UserOverview-detail-head'>
                        <img src={defaultUser} />
                    </div>
                    <div className='UserOverview-detail-overview'>
                        <div className='UserOverview-detail-name'>{userInfo.name}</div>
                        <div className='UserOverview-detail-describe'>{userInfo.gender === "1" ? "男" : "女"} {userInfo.age || "0"}岁</div>
                    </div>
                </div>

                <List>
                    <Link className='no-border' to="/follow">
                        <List.Item onClick={toSuifang}>
                            <div className='UserOverview-menu-btn' ><img src={icon1} className='UserOverview-menu-btn-icon' /> 随诊记录</div>
                        </List.Item>
                    </Link>
                    <Link className='no-border' to="/user">
                        <List.Item onClick={toDangan}>
                            <div className='UserOverview-menu-btn'><img src={icon2} className='UserOverview-menu-btn-icon' /> 我的档案</div>
                        </List.Item>
                    </Link>

                    <Link className='no-border'>
                        <List.Item onClick={logout}>
                            <div className='UserOverview-menu-btn'><img src={icon3} className='UserOverview-menu-btn-icon' /> 退出登录</div>
                        </List.Item>
                    </Link>
                </List>

                {/* 
                <Divider />

                <div className='UserOverview-menu-btn' onClick={toSuifang}>随诊记录</div>

                <Divider />

                <div className='UserOverview-menu-btn' onClick={toDangan}>我的档案</div>

                <Divider /> */}

                <img src={down} className='UserOverview-detail-close-btn' onClick={onClose} />
            </div>
        </Popup>
    );
}

export default UserOverview;
