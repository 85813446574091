import {
    Input,
    TextArea,
    Calendar,
    Popup,
    Picker,
    Button,
} from 'antd-mobile'
import "./formitem.scss";
import { useState } from 'react';

const CustomCalendar = ({ title, disabled, getValue, defaultValue }) => {
    const [visible, setVisible] = useState(false);
    const [value, setValue] = useState(defaultValue || "");
    return (
        <>
            <div className='FollowDetail-form-item' onClick={() => {
                if (disabled) return;
                setVisible(true)
            }}>
                <Input
                    className={`FollowDetail-form-item-input ${disabled ? 'adm-input-noBg' : ''}`}
                    style={{ opacity: 1 }}
                    disabled={true}
                    value={value}
                    placeholder={`请选择${title}`}
                />
            </div>
            <Popup onClose={() => setVisible(false)} showCloseButton visible={visible}>
                <div style={{ paddingTop: 40 }}>
                    <Calendar
                        onChange={(e) => {
                            const date = new Date(e);
                            const time = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
                            setValue(time);
                            getValue(time);
                            setVisible(false);
                        }}
                        selectionMode="single"
                    />
                </div>
            </Popup>
        </>
    )
}

const CustomPicker = ({ title, disabled, columns, getValue, defaultValue }) => {
    const [visible, setVisible] = useState(false);
    const [value, setValue] = useState(defaultValue || "");
    return (
        <>
            <div className='FollowDetail-form-item' onClick={() => {
                if (disabled) return;
                setVisible(true)
            }}>
                <Input
                    className={`FollowDetail-form-item-input ${disabled ? 'adm-input-noBg' : ''}`}
                    style={{ opacity: 1 }}
                    disabled={true}
                    value={value}
                    placeholder={`请选择${title}`}
                />
            </div>
            <Picker
                visible={visible}
                onConfirm={(v, b) => {
                    setValue(b.items[0].label)
                    getValue(v[0]);
                }}
                onClose={() => {
                    setVisible(false)
                }}
                columns={columns}
            />
        </>
    )
}

const SmsCode = ({ disabled, onChange, title, sendSms, phone, getValue, defaultValue }) => {
    const [btnValue, setBtnValue] = useState('发送')
    const [disabledClick, setDisabledClick] = useState(false);
    const onSendSms = () => {
        sendSms();
        let i = 59;
        setDisabledClick(true);
        let timer = setInterval(() => {
            setBtnValue(`${i}秒后重试`);
            if (i > 0) {
                i--;
            } else {
                clearInterval(timer);
                setDisabledClick(false)
                setBtnValue('发送')
            }
        }, 1000);
    }
    return (
        <div className='Sms-form-item' onClick={() => {
            if (disabled) return;
        }}>
            <Input
                className='FollowDetail-form-item-input'
                disabled={disabled}
                onChange={v => getValue(v)}
                placeholder={`请输入${title}`}
                maxLength={4}
                max={4}
            />

            <Button className='FollowDetail-form-item-btn' disabled={disabledClick} onClick={onSendSms}>
                {btnValue}
            </Button>
        </div>
    )
}

const CustomInput = (props) => {
    const [value, setValue] = useState(props.defaultValue || "");
    return <Input {...props} style={{ opacity: 1 }} className={`FollowDetail-form-item-input ${props.disabled ? 'adm-input-noBg' : ''}`} value={value} onChange={v => {
        props.getValue(v);
        setValue(v);
    }} />
}

const CustomTextArea = (props) => {
    const [value, setValue] = useState(props.defaultValue || "");
    return <TextArea {...props} style={{ opacity: 1 }} className={`FollowDetail-form-item-input ${props.disabled ? 'adm-input-noBg' : ''}`} value={value} onChange={v => {
        props.getValue(v);
        setValue(v);
    }} />
}

const formMap = {
    Input: CustomInput,
    TextArea: CustomTextArea,
    Calendar: CustomCalendar,
    Picker: CustomPicker,
    SmsCode: SmsCode,
}


function FormItem(props) {
    const Item = formMap[props.type];
    return (
        <div className='FollowDetail-form-item'>
            <div className='FollowDetail-form-item-title'>{props.require && <span className='FollowDetail-form-item-title-require'>*</span>}{props.title}</div>
            <Item {...props} className='FollowDetail-form-item-input' placeholder={`请输入${props.title}`} />
        </div>
    );
}

export default FormItem;