import axios from 'axios';
import { Toast } from 'antd-mobile';

function getHandle(host, isHttp) {
    return async (path, data, headers) => {
        // const toast = Toast.show({
        //     duration: 0,
        //     icon: 'loading',
        //     content: '加载中...',
        // })
        const params = {
            method: 'get',
            url: `${isHttp ? 'http' : 'https'}://${host}${path}`,
        }
        if (data) {
            params.params = data;
        }
        if (headers) {
            params.headers = headers;
        }
        try {
            const result = await axios(params);
            // toast.close();
            return result;
        } catch (e) {
            // toast.close();
            throw new Error(e);
        }
    }
}

function postHandle(host, isHttp) {
    return async (path, data, headers) => {
        // const toast = Toast.show({
        //     duration: 0,
        //     icon: 'loading',
        //     content: '加载中...',
        // })
        const params = {
            method: 'post',
            url: `${isHttp ? 'http' : 'https'}://${host}${path}`,
        }
        if (data) {
            params.data = data;
        }
        if (headers) {
            params.headers = headers;
        }
        try {
            const result = await axios(params);
            // toast.close();
            return result;
        } catch (e) {
            // toast.close();
            throw new Error(e);
        }
    }
}

export default function (host, useHttp) {
    return {
        get: getHandle(host, useHttp),
        post: postHandle(host, useHttp),
    }
}
