import React from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate, useLoaderData } from 'react-router-dom';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Toast } from 'antd-mobile';

import Doctor from './pages/doctor';
import Follow from './pages/follow';
import FollowDetail from './pages/follow-detail';
import User from './pages/user';
import DocWrite from './pages/doc-write';
import Chat from './pages/chat';
import Push from './pages/push';
import Login from './pages/login';
import Protocol from './pages/protocol';

import getPromptData from './services/getPromptData';

import { appConfig } from './constants';
const config = appConfig();
document.title = config.title;

const getPromptDataLoader = async () => {
  const toast = Toast.show({
    content: '加载中...',
    duration: 0,
    icon: 'loading'
  })
  const userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
  if (!userInfo) return 401;
  const result1 = await getPromptData(`${userInfo.id}-1`);
  const result2 = await getPromptData(`${userInfo.id}-2`);
  const result = [];
  toast.close();
  if (result1.code === 200 && result1.data) {
    result.push(result1.data);
    if (result2.code === 200 && result2.data) {
      result.push(result2.data);
    }
  }
  window.localStorage.setItem('promptData', JSON.stringify(result));
  return result
  // throw new Error(result.msg);
}

const Auth = (props) => {
  const auth = useLoaderData();
  const navigate = useNavigate();
  Toast.clear();
  if (auth === 401) {
    navigate('/login', { redirect: true });
  }
  return <props.Component loaderData={auth} />;
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Doctor />,
  },
  {
    path: "/follow",
    element: <Follow />,
  },
  {
    path: "/user",
    element: <User />,
  },
  {
    path: "/follow/:id",
    element: <Auth Component={FollowDetail} />,
    // loader: getPromptDataLoader,
  },
  {
    path: "/doc-write",
    element: <DocWrite />,
  },
  // {
  //   path: "/chat",
  //   element: <Auth Component={Chat} />,
  //   loader: getPromptDataLoader,
  // },
  {
    path: "/push",
    element: <Push />,
  },
  {
    path: "/login",
    element: <Login />
  },
  {
    path: "/protocol/user",
    element: <Protocol type="user" />
  },
  {
    path: "/protocol/privacy",
    element: <Protocol type="privacy" />
  }
]);

const App = () => {
  const userData = window.localStorage.getItem('userInfo');
  return (
    <RouterProvider router={router} />
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
