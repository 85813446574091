import docktorPic from './assets/doctor.png';
import doctorLsw from './assets/doctor-lsw.png';
import doctorLswHeader from './assets/doctor-header.png';

export const apiKey = "app-hy8GkWiVMtPl2cG6qk2roIBI";

const config = {
    cxct: {
        chatHref: "https://cxctadmin.metaio.cc/chat/WWLtSHKkrerJztS7?is_share=true&is_new=true",
        title: "卒息常谈",
        customService: 'https://work.weixin.qq.com/kfid/kfcfc47bf6adc51c104',
        appId: "1664a568-1a89-49e8-900d-a9ac0d786451",
        name: "孙勇",
        appName: "卒息常谈",
        pic: docktorPic,
        header: docktorPic,
        overview: [
            {
                title: '学术任职',
                content: '中国老年医学学会脑卒中分会委员，江苏省卒中学会理事，江苏省卒中学会脑脊髓血管病复合手术专委会常务委员，江苏省研究型医院学会神经介入专委会常务委员，连云港市医学会脑卒中分会副主任委员。',
            },
            {
                title: '科研成绩',
                content: '获连云港市科技进步二等奖1项，江苏省医学新技术引进二等奖1项，连云港市医学新技术引进一等奖1项、二等奖1项。',
            },
            {
                title: '个人荣誉',
                content: '江苏省“333高层次人才”、连云港市“521高层次人才”、连云港市“花果山英才”领军人才第一层次，南京医科大学康达学院教学督导专家、外科学基础教研室主任。',
            },
            {
                title: '专业特长',
                content: '擅长:脑血管病的微创神经介入治疗，如颅内动脉瘤栓塞术、脑动静脉畸形栓塞术、颈内动脉海绵窦瘘栓塞术、颅内外血管狭窄支架植入术、急性缺血性卒中的动脉取栓术等。',
            },
        ],
        info: ['连一医脑科医院党总支副书记', '神经外科常务副主任', '医学博士、主任医师、 硕士生导师'],
    },
    jzxd: {
        chatHref: "https://cxctadmin.metaio.cc/chat/5rpnBxlqmaaIzzzg?is_share=true&is_new=true",
        title: "揭卒先登",
        customService: "https://work.weixin.qq.com/kfid/kfc67baad5e290a4f1b",
        appId: "e88802af-9cf2-46ab-bdf2-ee9dd9c71560",
        name: "罗世炜",
        appName: "揭卒先登",
        pic: doctorLsw,
        header: doctorLswHeader,
        overview: [
            {
                style: {
                    marginTop: 50,
                },
                content: '罗世炜，男，揭阳市人民医院神经内三科副主任，副主任医师。广东省卒中学会缺血性神经介入分会第一届委员，揭阳市医师协会神经内科医师分会第一届委员会委员。曾在南京军区南京总医院进修神经介入，率先在本地区开展脑血管支架植入术和脑梗死取栓手术，填补了本地区多项空白。擅长脑梗死等脑血管病治疗。',
            },
            {
                title: '医生擅长',
                content: '对脑血管病，尤其对脑梗死有较深的造诣，擅长缺血性脑血管病药物治疗，擅长脑血管造影术，脑血管闭塞动脉取栓术，脑血管支架植入术等。',
            },
        ],
        info: ['副主任医师', '神经内科', '揭阳市人民医院'],
    },
};

export const appConfig = () => {
    if (window.location.hostname === 'localhost') return config['jzxd'];
    const app = window.location.host.split('.')[0];
    return config[app] || {};
}